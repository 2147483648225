import BillingForm from "../forms/BillingForm";
import { Col, Modal, Row } from "react-bootstrap";

import "./modal.scss";

import ResponsiveAccordion from "../selectedPlanAccordion/responsive/ResponsiveAccordion";
import { Box, useMediaQuery } from "@mui/material";
import SelectedInsurerAccordion from "../selectedPlanAccordion/SelectedInsurerAccordion";

import { IoMdClose } from "react-icons/io";

const SelectedInsurerModal = ({
  show,
  handleClose,
  insurer,
  setOpenPayPlan,
  setPlan,
}) => {
  const isDown1200 = useMediaQuery("(max-width:1200px)");
  return (
    <Modal
      centered
      show={show}
      backdrop="static"
      onHide={() => {
        //formik.resetForm()
        handleClose(false);
      }}
      keyboard={false}
      style={{ zIndex: 1500, backgroundColor: "transparent" }}
      backdropClassName="backdrop-on-top"
      dialogClassName="selected-plan-dialog"
      contentClassName="selected-plan-content bg-transparent border-0"
    >
      <Modal.Body>
        {isDown1200 ? (
          <ResponsiveAccordion
            insurer={insurer}
            handleClose={handleClose}
            setOpenPayPlan={setOpenPayPlan}
            setPlan={setPlan}
          />
        ) : (
          <>
            <Box position="fixed" sx={{ right: "15%" }}>
              <IoMdClose
                size={30}
                style={{ cursor: "pointer" }}
                onClick={() => handleClose(false)}
              />
            </Box>

            <SelectedInsurerAccordion
              selectedInsurer={insurer}
              handleClose={handleClose}
              setOpenPayPlan={setOpenPayPlan}
              setPlan={setPlan}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SelectedInsurerModal;
