import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  useTheme,
  FormControl,
  TextField,
  CircularProgress,
} from "@mui/material";
import { styles } from "../../styles/styles";

import "firebase/firestore";
import { ASbutton } from "../../components/buttons/ASbutton";
import { MdNotificationImportant } from "react-icons/md";

import { Provinces as provinces } from "../../utils/provinces";
import { Cantons as cantons } from "../../utils/cantons";
import { Districts as districts } from "../../utils/districts";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormik } from "formik";

import UploadImage from "../general/UploadImage";
import dayjs from "dayjs";
import { fsTimestampCreador } from "../../firebase/firebase-config";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { setIsLoadingInfo, updateUserData } from "../../store/actions/user";
import { uploadPhoto } from "../../store/actions/auth";
import { useDispatch } from "react-redux";
import { countries } from "../../utils/globals";

const MySwal = withReactContent(Swal);

export const EditProfileForm = ({
  handleRefresh,
  data,
  userId,
  loading,
  setLoading,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [filteredCantons, setFilteredCantons] = useState([]);
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [parsedDate, setParsedDate] = useState(dayjs("2005-01-01"));
  const [imageUpload, setImageUpload] = useState("");
  const [image, setImage] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [fileUrl, setFileUrl] = useState(null);

  const formatDate = (date) =>
    !date.seconds
      ? date // Already a javascript date object
      : date.toDate();

  useEffect(() => {
    if (data?.general?.birthday) {
      // Convert the user's birthday (timestamp or string) to a Dayjs object
      const birthdayDayjs = formatDate(data?.general?.birthday);
      const parsed = dayjs(birthdayDayjs);
      setParsedDate(parsed);
      // console.log([parsed]);
    }
    console.log(data);
  }, [data]);

  useEffect(() => {
    // Update formik initial values and the image state when data prop changes
    formik.setValues({
      name: data?.general?.name,
      lastName: data?.general?.lastName ?? "",
      secondLastName: data?.general?.secondLastName ?? "",
      email: data?.general?.email ?? "",
      birthday: parsedDate,
      civilState: data?.general?.civilState ?? "",
      gender: data?.general?.gender ?? "",
      idType: data?.private?.idType ?? "",
      address: data?.general?.address ?? "",
      country_id: data?.general?.country_id ?? "",
      province: data?.general?.province ?? "",
      canton: data?.general?.canton ?? "",
      district: data?.general?.district ?? "",

      personalId: data?.private?.personalId ?? "",
      phone: data?.private?.phone ?? "",
    });
    setImage(data?.general?.photoURL ?? "");
  }, [data]);

  const formik = useFormik({
    initialValues: {
      name: data?.general?.name,
      lastName: data?.general?.lastName,
      secondLastName: data?.general?.secondLastName ?? "",
      email: data?.general?.email ?? "",
      birthday: parsedDate,
      civilState: data?.general?.civilState ?? "",
      gender: data?.general?.gender ?? "0",
      idType: data?.private?.idType ?? "",
      address: data?.general?.address ?? "",
      country_id: data?.general?.country_id ?? "",
      province: data?.general?.province ?? "",
      canton: data?.general?.canton ?? "",
      district: data?.general?.district ?? "",

      personalId: data?.private?.personalId ?? "",
      phone: data?.private?.phone ?? "",
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        handleRegister(values);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleRegister = async (values) => {
    const timestampBirthday = fsTimestampCreador.fromDate(
      new Date(formik.values.birthday)
    );

    const publicData = {
      name: values.name,
      lastName: values.lastName,
      secondLastName: values.secondLastName ?? "",
      province: values.province,
      canton: values.canton,
      district: values.district,
      address: values.address,
      birthday: timestampBirthday,
      gender: values.gender,
      country_id: values.country_id,
      postalCode: postalCode ? postalCode.toString() : "",
    };

    const privateData = {
      personalId: values.personalId,
      idType: values.idType,
      phone: values.phone ?? "",
    };

    const data = {
      isWeb: true,
    };
    console.log("Public Data:", publicData);
    console.log("Private Data:", privateData);
    const confirmResult = await MySwal.fire({
      title: "Confirmar edición",
      text: "¿Estás seguro de que deseas editar tu perfil?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, editar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    });

    if (confirmResult.isConfirmed) {
      setLoading(true);
      dispatch(setIsLoadingInfo(true));
      try {
        console.log(imageUpload);
        if (imageUpload !== "") {
          await uploadPhoto(userId, imageUpload);
        }

        await updateUserData(userId, publicData, privateData, "data");
        handleRefresh();

        MySwal.fire("Guardado!", "", "success");
      } catch (error) {
        MySwal.fire("Error", "Hubo un error al editar tu perfil", "error");
      }

      handleRefresh();
      setLoading(false);
      dispatch(setIsLoadingInfo(false));
    }
  };

  useEffect(() => {
    const selectedProvince = formik.values.province;

    if (selectedProvince) {
      const filterItems = cantons.filter(
        (canton) => canton.provinceName === selectedProvince
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });
      setFilteredCantons(filterItems);
    }
  }, [formik.values.province]);

  useEffect(() => {
    const selectedCanton = formik.values.canton;

    if (selectedCanton) {
      const filterItems = districts.filter(
        (district) => district.cantonName === selectedCanton
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });
      setFilteredDistricts(filterItems);
    }
  }, [formik.values.canton]);

  const handleDistrictChange = (value) => {
    if (typeof value !== "string" && typeof value !== "number") {
      console.log("Invalid value type received:", typeof value, value);
      return;
    }
    const selectedObj = districts.find((district) => district.value === value);
    if (!selectedObj) {
      console.log("No matching district found for value:", value);
      return;
    }
    setSelectedDistrict(selectedObj);
    setpostalCode(selectedObj?.districtId);
    formik.setFieldValue("district", selectedObj?.label);
    formik.setFieldValue("postalCode", selectedObj?.districtId);
  };

  const civilState = [
    {
      value: "single",
      label: "Soltero",
    },
    {
      value: "married",
      label: "Casado",
    },
  ];
  const gender = [
    {
      value: "0",
      label: "Masculino",
    },
    {
      value: "1",
      label: "Femenino",
    },
  ];

  if (loading) {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "450px",
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box sx={styles.formContainer}>
      <form style={{ padding: 30 }}>
        <UploadImage
          loadUrl={setFileUrl}
          imageUpload={imageUpload}
          setImageUpload={setImageUpload}
          setImage={setImage}
          image={image}
        />
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                label="Nombre"
                type="name"
                id="name"
                name="name"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                label="Apellido"
                type="lastName"
                id="lastName"
                name="lastName"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                label="Segundo Apellido"
                type="secondLastName"
                id="secondLastName"
                name="secondLastName"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondLastName}
                error={
                  formik.touched.secondLastName &&
                  Boolean(formik.errors.secondLastName)
                }
                helperText={
                  formik.touched.secondLastName && formik.errors.secondLastName
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Teléfono"
                name="phone"
                id="phone"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Email"
                type="email"
                id="email"
                name="email"
                variant="outlined"
                value={data?.general?.email}
              />
            </FormControl>
          </Grid>
        </Grid>

        {/* DOB CIVIL */}

        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <DatePicker
                label="Fecha de Nacimiento"
                id="birthday"
                name="birthday"
                value={formik.values.birthday}
                onChange={(newValue) => {
                  formik.setFieldValue("birthday", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    error={
                      formik.touched.birthday && Boolean(formik.errors.birthday)
                    }
                    helperText={
                      formik.touched.birthday && formik.errors.birthday
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Seleccione"
                id="civilState"
                name="civilState"
                select
                value={formik.values.civilState}
                onChange={(e) =>
                  formik.setFieldValue("civilState", e.target.value)
                }
                error={
                  formik.touched.civilState && Boolean(formik.errors.civilState)
                }
                helperText="Seleccione el estado civil"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdNotificationImportant />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              >
                {civilState.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Seleccione"
                id="gender"
                name="gender"
                select
                value={formik.values.gender}
                onChange={(e) => formik.setFieldValue("gender", e.target.value)}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helperText="Seleccione el género"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdNotificationImportant />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              >
                {gender.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>

        {/* NATIONALITY */}

        <Grid container spacing={2} mb={4}>
          {/* <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Seleccione"
                id="idType"
                name="idType"
                select
                value={formik.values.idType}
                onChange={(e) => formik.setFieldValue("idType", e.target.value)}
                error={formik.touched.idType && Boolean(formik.errors.idType)}
                helperText="Seleccione el tipo de cedula"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdNotificationImportant />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              >
                {idTypesData.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid> */}
          <Grid item xs={12} sm={12} md={8}>
            <FormControl fullWidth>
              <TextField
                label={"Cédula Nacional"}
                type="number"
                id="personalId"
                variant="outlined"
                onChange={(e) =>
                  formik.setFieldValue("personalId", e.target.value)
                }
                onBlur={formik.handleBlur}
                value={formik.values.personalId}
                error={
                  formik.touched.personalId && Boolean(formik.errors.personalId)
                }
                helperText={
                  formik.touched.personalId && formik.errors.personalId
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        {/* PROVINCIAS */}

        <Grid container spacing={2} mb={4}>
          {/* <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="País"
                id="country_id"
                name="country_id"
                select
                value={formik.values.country_id}
                onChange={
                  (e) => formik.setFieldValue("country_id", e.target.value) 
                }
                error={
                  formik.touched.country_id && Boolean(formik.errors.country_id)
                }
                helperText="Seleccione el país"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdNotificationImportant />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              >
                {countries.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name} 
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid> */}

          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Provincia"
                id="province"
                name="province"
                select
                value={formik.values.province}
                error={
                  formik.touched.province && Boolean(formik.errors.province)
                }
                onChange={(e) =>
                  formik.setFieldValue("province", e.target.value)
                }
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdNotificationImportant />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              >
                {provinces.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Cantón"
                id="canton"
                name="canton"
                select
                value={formik.values.canton}
                onChange={formik.handleChange}
                // error={formik.touched.canton && Boolean(formik.errors.canton)}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdNotificationImportant />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              >
                {filteredCantons.map((option) => (
                  <MenuItem key={option.label} value={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                label="Distrito"
                name="district"
                select
                value={formik.values.district}
                onChange={(e) => {
                  const selectedLabel = e.target.value;
                  formik.setFieldValue("district", selectedLabel);

                  // If you also need postal code or other data:
                  const match = filteredDistricts.find(
                    (d) => d.label === selectedLabel
                  );
                  if (match) {
                    setpostalCode(match.districtId); // local state, if needed
                    formik.setFieldValue("postalCode", match.districtId);
                  }
                }}
                variant="standard"
              >
                {filteredDistricts.map((option) => (
                  <MenuItem key={option.label} value={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
        {/* ADDRESS */}

        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={12} md={8}>
            <FormControl fullWidth>
              <TextField
                label="Dirección"
                type="address"
                id="address"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Seleccione Sistema Operativo de el Dispositvo"
                id="device"
                name="device"
                select
                value={formik.values.device}
                onChange={(e) => formik.setFieldValue("device", e.target.value)}
                helperText={formik.touched.device && formik.errors.device}
                error={Boolean(formik.errors.device)}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdNotificationImportant />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              >
                {platform.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid> */}
        </Grid>
      </form>
      <Grid
        container
        sx={styles.buttonsContainer}
        justifyContent={"flex-end"}
        direction="row"
      >
        {/* <Grid item>
          <ASbutton
            type="submit"
            backgroundColor={"grey"}
            color={"white"}
            disabled={activeStep === 0}
            buttonText={"Cancelar"}
          />
        </Grid> */}
        <Grid item>
          <Box ml={2}>
            <ASbutton
              onClick={formik.handleSubmit}
              backgroundColor={theme.palette.primary.main}
              color={"white"}
              buttonText={"Guardar"}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
