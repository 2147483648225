import { db } from "../../firebase/firebase-config";
import { API_URL } from "../../utils/globals";
import { types } from "../types/types";
import firebase from "firebase/compat/app";

export const SET_SPONSORS = "SET_SPONSORS";

export const getSponsorsByProvince = () => {
  return async (dispatch) => {
    const data = await db
      .collection("sponsors")
      .where("isActive", "==", true)
      .get();

    const sponsors = [];

    // Iterate through the sponsor documents
    for (const doc of data.docs) {
      const sponsorData = doc.data();
      const sponsorId = doc.id;

      // Fetch branches for the current sponsor
      const branchesSnapshot = await db
        .collection("sponsors")
        .doc(sponsorId)
        .collection("branches")
        .get();

      const branches = [];

      branchesSnapshot.forEach((branchDoc) => {
        const branchData = branchDoc.data();
        branches.push({ ...branchData, id: branchDoc.id });
      });

      // Add the province information to the sponsorData
      sponsorData.province = branches.length > 0 ? branches[0].province : null;

      // Here, you can access the data from the first branch
      // You can add it to the sponsors array or dispatch it as needed
      const sponsorWithBranchData = {
        province: (sponsorData.province =
          branches.length > 0 ? branches[0].province : null),
        logo: sponsorData.logo,
        branches: branches, // Store branch data within the sponsor object
      };

      sponsors.push({ ...sponsorWithBranchData, id: sponsorId });
    }

    // Now, you have an array of sponsors with branch data (if applicable)
    dispatch(getSponsors(sponsors));
  };
};

export const getMainSponsors = async () => {
  try {
    const querySnapshot = await db
      .collection("utils")
      .doc("mainSponsors")
      .collection("landingSponsors")
      .orderBy(firebase.firestore.FieldPath.documentId())
      .get();

    const sponsors = [];

    querySnapshot.forEach((doc) => {
      const sponsorData = doc.data();
      sponsors.push({
        id: doc.id,
        ...sponsorData,
      });
    });

    return sponsors;
  } catch (error) {
    console.error("Error getting main sponsors:", error);
    return [];
  }
};

export const getAllOverallCoins = async () => {
  const url = `https://webutils-tllml4bkja-uc.a.run.app/getTotalCaloriesConverted`;

  const headers = {
    "Content-Type": "application/json",
  };

  const options = {
    method: "GET",
    headers: headers,
  };

  try {
    const response = await fetch(url, options);

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getSponsors = (sponsors) => ({
  type: SET_SPONSORS,
  payload: sponsors,
});
