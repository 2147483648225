import { BiSolidLeftArrow } from "react-icons/bi";
import { BiSolidRightArrow } from "react-icons/bi";

export const NextArrow = ({ onClick }) => (
  <span onClick={onClick}>
    <BiSolidRightArrow
      size={45}
      color="#3e3188"
      style={{
        cursor: "pointer",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: 0,
      }}
    />
  </span>
);

export const PrevArrow = ({ onClick }) => (
  <span onClick={onClick}>
    <BiSolidLeftArrow
      size={45}
      color="#3e3188"
      style={{
        cursor: "pointer",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 20,
      }}
    />
  </span>
);
