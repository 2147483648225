//import { color } from "three/examples/jsm/nodes/Nodes.js";
import theme from "./theme";

export const styles = {
  promoCard: {
    display: "flex",
    flexDirection: "column",
    minHeight: "650px",
    alignItems: "center",
    pt: 3,
    pr: 2.5,
    pl: 2.5,
    borderRadius: "18px",
    marginTop: 2,
    backgroundColor: "red",
    [theme.breakpoints.down("md")]: {
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
    },
    [theme.breakpoints.down("xl")]: {
      py: 3,
      minHeight: "750px",
    },
  },

  innerCard: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    minHeight: "350px",
    borderRadius: "18px",
    width: "100%",
    pr: 5,
    pl: 5,

    [theme.breakpoints.down("md")]: {
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
      pr: 2,
      pl: 2,
    },
    [theme.breakpoints.down("xl")]: {
      minHeight: "450px",
      pr: 2,
      pl: 2,
    },
  },
  bottomCard: {
    minHeight: "450px",
    width: "100%",

    pr: 10,
    pl: 10,
    borderRadius: "18px",

    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
      minHeight: "75vh",
      pr: 2,
      pl: 2,
    },
    [theme.breakpoints.up("xxl")]: {
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
      minHeight: "40vh",
      pr: 2,
      pl: 2,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 !important",
    },
  },
  sponsorCard: {
    minHeight: "600px",
    width: "100%",

    pr: 10,
    pl: 10,
    borderRadius: "18px",

    [theme.breakpoints.down("lg")]: {
      pr: 2,
      pl: 2,
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
      minHeight: "800px",
    },
  },
  faqCard: {
    minHeight: "800px",
    alignItems: "flex-start",
    width: "100%",
    pr: 10,
    pl: 10,
    borderRadius: "18px",
    [theme.breakpoints.down("md")]: {
      pr: 2,
      pl: 2,
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
    },
  },
  formCard: {
    display: "flex",

    flexDirection: "column",
    borderRadius: "18px",
    borderWidth: "2px !important",
    border: "solid",
    borderColor: "#ECECEE",
    minHeight: "63vh",
    paddingRight: "20px",
    paddingLeft: "20px",
    // marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
      p: 1,
    },

    paddingBottom: "20px",
  },
  planSelection: {
    borderRadius: "18px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100% !important",
    pb: 4,
    overflow: "hidden",
  },
  plansCard: {
    display: "flex",
    flexDirection: "column",
    minHeight: "70vh",

    alignItems: "center",

    paddingRight: "20px",
    paddingLeft: "20px",
    marginTop: 2,
    [theme.breakpoints.down("md")]: {
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
    },
  },
  summaryCard: {
    width: "100%",
    borderRadius: "18px",
    position: "relative",
    borderWidth: "2px !important",
    border: "solid",
    borderColor: "#ECECEE",
    p: 2,
  },
  contactCard: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",

    minHeight: "200px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "50vh",
    paddingRight: "20px",
    marginTop: 4,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
    },
    paddingBottom: "20px",
  },
  profileWrapper: {
    // backgroundColor: "grey",

    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",

    width: "96%",
    borderRadius: "18px",
    borderWidth: "2px !important",
    border: "solid",
    borderColor: "#ECECEE",
    // justifyContent: "center",
    // alignItems: "center",
    mt: 14,
    mb: 14,
    pt: 2,
    pb: 6,
  },
  buttonsContainer: {
    // marginTop: "4em",
    marginTop: "auto",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2em",
    },
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 15,
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "18px",
    minHeight: "325px",
    maxHeight: "325px",
    borderWidth: "2px",
    border: "solid",
    borderColor: "#ECECEE",
    backgroundColor: "white",
  },
  sponsorsList: {
    borderRadius: "18px",
    backgroundColor: "white",
    borderWidth: "2px",
    border: "solid",
    borderColor: "#ECECEE",
  },

  titleGridContainer: {
    marginTop: 5,
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "50px",
    marginRight: "auto",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
    // maxWidth: '1330px',
  },
  titleCard: {
    // width:300,
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,

    padding: "0.3rem 1.5rem 0.3rem 1.5rem",
    borderRadius: "18px",
    textAlign: "center",
    // clipPath: "polygon(0 0, 100% 0, 90% 96%, 0 100%)",
  },
  boldTextTerms: {
    fontWeight: "800 !important",
    fontSize: "1.3rem !important",
    mb: 2,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem !important",
    },
  },
  boldTextTerms2: {
    fontWeight: "800 !important",
    fontSize: "1.1rem !important",

    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem !important",
      mt: 2,
    },
  },
  boldTextLight: {
    fontWeight: "800 !important",
    fontSize: "1.3rem !important",
    color: "white",
    textAlign: "center",
    userSelect: "none",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem !important",
      textAlign: "center",
    },
  },
  planSelectionLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "18px",

    borderWidth: "2px",
    border: "solid",
    borderColor: "#ECECEE",
  },

  blogItem: {
    position: "relative",
    alignSelf: "center",
    //width: "100%",
    [theme.breakpoints.up("md")]: {
      //height: 250,
    },
    justifyContent: "center",
    backgroundColor: "white",
    px: 2,
    paddingTop: 2,
    paddingBottom: 1,
    borderRadius: "18px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  blogName: {
    fontSize: 18,
    fontWeight: "bold",
  },
  blogAuthor: {
    fontSize: 14,
    fontWeight: "regular",
  },
  blogContent: {
    fontSize: 12,
    width: "100%",
    wordBreak: "break-word",
    transition: "color 0.3s ease", // Smooth transition for color change
    "&:hover": {
      color: "blue", // Change color on hover
    },
  },
  blogContent2: {
    fontSize: 12,
    width: "100%",
  },
  tag: {
    marginRight: 1,
    backgroundColor: theme.palette.primary.main,
    justifyContent: "center",
    paddingHorizontal: 1,

    borderRadius: 5,
  },
  tagText: {
    marginRight: 5,
    color: "light",
    textAlign: "center",
    borderRadius: 5,
  },
  tagsContainer: {
    marginTop: 2,
    // height: 35,
  },
  viewMore: {
    borderWidth: 0,
    boxShadow: "none",
    marginLeft: "auto",
    //position: "absolute",
    // bottom: 0,
    // right: 10,
    color: theme.palette.primary.main,
  },

  title: {
    fontSize: 20,
    color: "#6E6B78",
    textAlign: "left",
    textAlignVertical: "center",
    marginBottom: 1,
  },
  blogsMainTitle: {
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 38,
    },
    color: theme.palette.primary.main,
  },
  textContainer: {
    marginBottom: 10,
    wordBreak: "break-word",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    fontSize: 14,
    marginBottom: 2,
  },
  contentParagraph: {
    marginBottom: 2,
    textAlign: "justify",
  },

  commentInput: {
    flex: 1,

    borderRadius: 20,
    paddingHorizontal: 15,
    paddingTop: 10,
    paddingBottom: 10,
  },
  actionBar: {
    marginTop: 2,
    paddingTop: 2,
    gap: 2,
    borderTopWidth: 1,
  },
  commentButton: {
    flexDirection: "row",
    alignItems: "center",

    justifyContent: "flex-end",
  },
  commenteButtonText: {
    marginLeft: 5,
    fontSize: 12,
  },

  likeButton: {
    flexDirection: "row",
    alignItems: "center",

    justifyContent: "flex-end",
  },
  likeButtonText: {
    marginLeft: 5,
    fontSize: 12,
  },

  commentContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    p: 4,
    width: "100%",
    height: 100,

    borderRadius: "18px",
    boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
  },
  commentInputContainer: {
    boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    borderRadius: "18px",
  },
  commentItem: {
    alignSelf: "center",

    justifyContent: "center",
    backgroundColor: "white",
    paddingHorizontal: 2,
    paddingTop: 2,
    paddingBottom: 1,
    borderRadius: 1,
  },
  commentText: {
    fontSize: 14,
    marginBottom: 1,
  },
  commentBox: {
    p: 2,
    border: "1px solid #CBCBCB",
    borderRadius: "12px",
    minHeight: 125,
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    backgroundColor: "#fdfdfd",
  },
  dateText: {
    fontSize: 12,
    color: "grey",
  },
  sendIcon: {
    marginLeft: 10,
  },
  sendIcon: {
    marginLeft: 10,
  },
  postImage: {
    backgroundColor: "white",
    borderRadius: "12px",
    width: "100%",
  },
};
