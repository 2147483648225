import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/analytics";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD_PQrP0LBNN6bP3jZPckLxob4HV4prTvE",
  authDomain: "allsports-69231.firebaseapp.com",
  databaseURL: "https://allsports-69231-default-rtdb.firebaseio.com",
  projectId: "allsports-69231",
  storageBucket: "allsports-69231.appspot.com",
  messagingSenderId: "799750229360",
  appId: "1:799750229360:web:dba89bcfb037e9d684d3b5",
  measurementId: "G-0WB04RTK6R",
};

firebase.initializeApp(firebaseConfig);

export const googleProvider = new firebase.auth.GoogleAuthProvider();
// googleProvider.setCustomParameters({
//   prompt: "select_account",
// });

const fsTimestampCreador = firebase.firestore.Timestamp;
const db = firebase.firestore();

const auth = firebase.auth();
const analytics = firebase.analytics();
const storageRef = firebase.storage();

export { auth, db, analytics, fsTimestampCreador, storageRef };

let firebaseAnalytics;

export const initFirebaseAnalytics = async () => {
  if (!firebaseAnalytics) {
    // Import the Firebase Analytics module if it hasn't been imported yet.
    await import("firebase/analytics");

    // Initialize Firebase Analytics and store the instance in the firebaseAnalytics variable.
    firebaseAnalytics = await firebase.analytics();
  }
  // Return the Firebase Analytics instance.
  return firebaseAnalytics;
};
