import React, { useEffect, useState } from "react";
import {
  Dialog,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import SignUpForm from "../forms/SignUpForm";
import PersonalInformationForm from "../forms/PersonalInformation";
import PurchaseSummaryForm from "../forms/PurchaseSummary";
import Podium from "../podium/Podium";
import { useSelector } from "react-redux";
import { getOneUser } from "../../store/actions/user";

const DialogWithSteps = ({ open, setOpen, plan, openLogIn }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [userData, setUserData] = useState({});

  const { user } = useSelector((state) => state.auth);
  const { userInfo } = useSelector((state) => state.user);
  const matchesMax = useMediaQuery("(max-width:768px)");
  const matchesMin = useMediaQuery("(min-width:1200px)");

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    const getUserData = async () => {
      const data = await getOneUser(user.uid);

      setUserData(data);
    };

    if (user) {
      getUserData();
    }
  }, [user]);

  const Step1Content = () => {
    return (
      <div>
        <SignUpForm
          handleNext={handleNext}
          handleClose={setOpen}
          handleOpenLogIn={openLogIn}
        />
      </div>
    );
  };

  const Step2Content = () => {
    return (
      <div>
        <PersonalInformationForm handleNext={handleNext} userData={userData} />
      </div>
    );
  };

  const Step3Content = () => {
    return (
      <div>
        <PurchaseSummaryForm
          handleNext={handleNext}
          plan={plan}
          handleBack={handleBack}
        />
      </div>
    );
  };

  const Step4Content = () => {
    return (
      <div className="d-flex justify-content-center align-items-center w-50">
        <div className="align-self-center">
          <Podium plan={plan} />
        </div>
      </div>
    );
  };

  const noLogInSteps = [
    { label: "Paso 1", content: <Step1Content /> },
    { label: "Paso 2", content: <Step2Content /> },
    { label: "Paso 3", content: <Step3Content /> },
    { label: "Paso 4", content: <Step4Content /> },
  ];

  const logInSteps = [
    { label: "Paso 1", content: <Step2Content /> },
    { label: "Paso 2", content: <Step3Content /> },
    { label: "Paso 3", content: <Step4Content /> },
  ];

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: matchesMax
            ? {
                bgcolor: "#f5f6ff",
                borderRadius: "55px",
                width: "100%",
                minHeight: "75vh",
                // height: "100%",
              }
            : {
                bgcolor: "#f5f6ff",
                padding: "2rem",
                borderRadius: "55px",
                width: "100%",
                height: "75vh",
                minHeight: "75vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "space-between",
                [theme.breakpoints.up("lg")]: {
                  minHeight: "75vh",
                },
              },
        }}
        open={open}
        onClose={() => {
          setActiveStep(0);
          setOpen(false);
        }}
      >
        <Box
          sx={{
            flex: 1,
            width: "100%",
            height: "100%",
            [theme.breakpoints.down("lg")]: {
              padding: "2rem",
            },
          }}
        >
          {user
            ? logInSteps[activeStep].content
            : noLogInSteps[activeStep].content}
        </Box>
      </Dialog>
    </div>
  );
};

export default DialogWithSteps;

// disabled={!(formik.isValid && formik.dirty)}
