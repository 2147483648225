import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { ASbutton } from "../buttons/ASbutton";

import Cards from "react-credit-cards-2";

import { useSelector } from "react-redux";
import { getCardType } from "../../utils/validateIssuer";

const CardList = ({ creditCardsArray, maskedCreditCardsArray, showAlert }) => {
  const { userInfo } = useSelector((state) => state.user);

  const handleClick = (cardIndex) => {
    const updatedCreditCards = creditCardsArray.map((card, index) => {
      if (cardIndex === index) {
        return { ...card, isDefault: true };
      } else {
        return { ...card, isDefault: false };
      }
    });

    const data = { creditCards: updatedCreditCards };

    showAlert(
      "Seleccionar esta tarjeta como predeterminada?",
      userInfo.userId,
      data,
      "wallet"
    );
  };

  const handleDelete = (cardIndex) => {
    const updatedCreditCards = creditCardsArray.filter((_, index) => {
      if (cardIndex === index) {
        return false;
      } else {
        return true;
      }
    });

    const data = { creditCards: updatedCreditCards };

    showAlert("Desea eliminar esta tarjeta?", userInfo.userId, data, "wallet");
  };

  return (
    <Box sx={{ paddingLeft: "30px" }}>
      <Typography variant="h4" component="h2" mb={3}>
        Lista de tarjetas
      </Typography>

      <Grid container spacing={3}>
        {maskedCreditCardsArray.map((cardItem, index) => {
          const formatted = creditCardsArray[index].cardNumber.replace(
            /\s/g,
            ""
          );
          console.log(cardItem.cardNumber.replace(/\s/g, ""));
          console.log(getCardType(formatted));
          return (
            <Grid item>
              <Box>
                <FormControlLabel
                  control={<Checkbox checked={cardItem.isDefault} />}
                  label="Predeterminada"
                  onClick={() => {
                    if (!cardItem.isDefault) handleClick(index);
                  }}
                />
                <Cards
                  //name={cardItem.cardHoldersName}
                  name=" "
                  number={cardItem.cardNumber}
                  expiry={cardItem.expirationDate}
                  preview={true}
                  issuer={getCardType(formatted)}
                />

                <Box display="flex" justifyContent="space-around" my={3}>
                  <ASbutton
                    buttonText={"Eliminar"}
                    onClick={() => handleDelete(index)}
                  />
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default CardList;
