export const useColumns = () => {
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "transactionDate",
      label: "Fecha",
      options: {
        customBodyRender: (value) => {
          return value
            ? value.toDate().toLocaleDateString("es-CR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            : "N/A";
        },
        filter: false,
      },
    },

    {
      name: "transactionId",
      label: "Número de transacción",
      options: {
        filter: false,
      },
    },

    {
      name: "plan",
      label: "Plan",
      options: {
        filter: false,
      },
    },
    {
      name: "insurer",
      label: "Aseguradora",
      options: {
        filter: false,
      },
    },
    {
      name: "transactionAmount",
      label: "Cantidad",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },
        filter: false,
      },
    },
  ];

  return columns;
};
