import jwtDecode from "jwt-decode";
import Form from "react-bootstrap/Form";
import { Stack, Button, Row, Col, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Provinces as provinces } from "../../utils/provinces";
import { Cantons as cantons } from "../../utils/cantons";
import { Districts as districts } from "../../utils/districts";

import { useFormik } from "formik";

import { FiArrowRight } from "react-icons/fi";

import exerciseGirl from "../../assests/exercise-girl.jpg";

import { useDispatch, useSelector } from "react-redux";

import "./formStyles.scss";
import { useState, useEffect } from "react";
import { personalInformationFormSchema } from "../../schemas/personalInformation";
import PurchaseRaceProgress from "../PurchaseRaceProgress/PurchaseRaceProgress";

import { fsTimestampCreador } from "../../firebase/firebase-config";
import { useTheme } from "@emotion/react";
import {
  checkAge,
  countries,
  getUserInfoAPI,
  idTypesData,
} from "../../utils/globals";
import { refreshToken } from "../../store/actions/auth";
import { updateUserData } from "../../store/actions/user";
import { useNavigate } from "react-router-dom";

const UpdateUserProfileForm = ({ setOpen, userData }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { idToken } = useSelector((state) => state.auth);
  const [filteredCantons, setFilteredCantons] = useState([]);
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const currentUser = useSelector((state) => state.auth.user);
  const errorCode = useSelector((state) => state.auth.errorCode);
  const userProfile = useSelector((state) => state.user.userProfile);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editableDate, setEditableDate] = useState(false); // Editable if birthday is not set
  const [isNextValid, setIsNextValid] = useState(false);

  const navigateTo = (route) => {
    navigate(route);
  };
  const {
    setFieldValue,
    handleChange,
    setValues,
    touched,
    errors,
    values,
    isValid,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      idType: "Seleccione una opción",
      personalId: userData?.personalId ?? "",
      dateOfBirth: userData?.birthday ? new Date(userData.birthday) : startDate,
      country_id: userData?.country_id ?? "", // New field for country
      province: userData?.province ?? "",
      canton: userData?.canton ?? "",
      district: userData?.district ?? "",
      termsAndConditions: false,
      notificationPermission: userData?.notificationPermission ?? false,
    },

    validationSchema: personalInformationFormSchema,
    onSubmit: (values) => {
      const publicData = {
        cdate: fsTimestampCreador.fromDate(new Date()),
        name: userProfile?.name ? userProfile?.name : "",
        lastName: userProfile?.lastName ?? "",
        secondLastName: userProfile?.secondLastName ?? "",
        province: values.province,
        canton: values.canton,
        district: values.district,
        notificationPermission: values.notificationPermission,
        birthday: fsTimestampCreador.fromDate(values.dateOfBirth),
        skipIntro: false,
        isActiveMember: false,
        infoCompleted: false,
      };

      const privateData = {
        personalId: values.personalId,
        idType: values.idType,
      };

      updateUserData(currentUser.uid, publicData, privateData, "data");
      navigateTo("/about-us");
    },
  });

  useEffect(() => {
    if (errorCode !== "" && errorCode !== undefined) {
      setOpen();
    }
  }, [errorCode]);

  useEffect(() => {
    const selectedProvince = values.province;

    if (selectedProvince) {
      const filterItems = cantons.filter(
        (canton) => canton.provinceName === selectedProvince
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });
      setFilteredCantons(filterItems);
    }
  }, [values.province]);

  useEffect(() => {
    const selectedCanton = values.canton;

    if (selectedCanton) {
      const filterItems = districts.filter(
        (district) => district.cantonName === selectedCanton
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });
      setFilteredDistricts(filterItems);
    }
  }, [values.canton]);
  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  const fetchUserData = async () => {
    try {
      let idTypeFormatted = "0";
      if (values.idType === "01") idTypeFormatted = "0";
      else if (values.idType === "02") idTypeFormatted = "1";
      else if (values.idType === "03") idTypeFormatted = "2";
      else if (values.idType === "04") idTypeFormatted = "3";

      let token = "";
      if (isValidToken(idToken)) {
        token = idToken;
      } else {
        token = await refreshToken();
      }

      const response = await getUserInfoAPI(
        values.idType,
        values.personalId,
        token
      );

      if (response && response.client) {
        const client = response.client;

        if (!checkAge(client.dateOfBirth)) {
          setErrorMessage(
            "La fecha de nacimiento indica que el usuario es menor de 18 años."
          );
          setEditableDate(true);
          setIsNextValid(false);
          return;
        }

        setValues((prevValues) => ({
          ...prevValues,
          name: client.name ?? "",
          lastName: client.lastName ?? "",
          secondLastName: client.secondLastName ?? "",
          phone: client.phoneNumbers.length ? client.phoneNumbers[0] : "",
          personalId: client.personalId ?? prevValues.personalId,
          province: prevValues.province,
          canton: prevValues.canton,
          district: prevValues.district,
          dateOfBirth: client.dateOfBirth ? new Date(client.dateOfBirth) : null,
        }));

        setEditableDate(!client.dateOfBirth);
        setIsNextValid(true);
      } else {
        setErrorMessage(
          "No se encontró información para el ID proporcionado. Por favor, inténtelo de nuevo."
        );
        setIsNextValid(false);
      }
    } catch (error) {
      console.error("Error fetching user data", error);
      setErrorMessage(
        "Ocurrió un error. Por favor ingrese la fecha de nacimiento manualmente."
      );
      setEditableDate(true);
      setIsNextValid(false);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div
      style={{
        backgroundColor: "#f5f6ff",
        padding: "20px",
        borderRadius: "55px",
      }}
    >
      <Row>
        <Col xl="7" lg="12">
          <PurchaseRaceProgress progressPorcentage={90} />
          {/* <Stepper activeStep={activeStep} alternativeLabel variant="progress">
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper> */}
          <h4 className="fw-bold my-3">Información Personal</h4>
          <form>
            <Row>
              <Form.Group
                className="mb-3"
                controlId="groupIdType"
                as={Col}
                md="6"
              >
                <Form.Label>Tipo de documento</Form.Label>
                <Form.Select
                  className="mb-3"
                  aria-label="Tipo de documento"
                  value={values.idType}
                  onChange={handleChange}
                  name="idType"
                  onBlur={handleBlur}
                  isInvalid={!!errors.idType && touched.idType}
                >
                  <option>Seleccione una opcion</option>
                  {idTypesData.map((type) => {
                    return <option>{type.label}</option>;
                  })}
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  {errors.idType}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="groupId" as={Col} md="6">
                <Form.Label>Número de documento</Form.Label>
                <Form.Control
                  type="number"
                  className="mb-3"
                  placeholder="Número de documento"
                  onChange={handleChange}
                  name="personalId"
                  isInvalid={!!errors.personalId && touched.personalId}
                  onBlur={handleBlur}
                  value={values.personalId}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.personalId}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Stack direction="horizontal" className="mt-2 mb-4" gap={2}>
              <Button
                style={{
                  padding: "10px 15px",
                  borderRadius: "15px",
                  minWidth: "150px",
                }}
                onClick={fetchUserData}
                variant="secondary"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Continuar"
                )}
              </Button>
            </Stack>

            <Row>
              <Form.Group
                className="mb-3"
                controlId="groupDate"
                as={Col}
                md="6"
              >
                <Form.Label>Fecha de nacimiento</Form.Label>
                <DatePicker
                  selected={
                    values.dateOfBirth
                      ? new Date(values.dateOfBirth)
                      : startDate
                  }
                  onChange={(val) => setFieldValue("dateOfBirth", val)}
                  name="dateOfBirth"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="form-control"
                  disabled={!editableDate}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="groupCountry"
                as={Col}
                md="6"
              >
                <Form.Label>País</Form.Label>
                <Form.Select
                  aria-label="País"
                  value={values.country_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.country_id && touched.country_id}
                  name="country_id"
                >
                  <option>Seleccione un país</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.flag} {country.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.country_id}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="groupProvince"
                as={Col}
                md="6"
              >
                <Form.Label>Provincia</Form.Label>
                <Form.Select
                  aria-label="Provincia"
                  value={values.province}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.province && touched.province}
                  name="province"
                >
                  <option>Seleccione una opcion</option>
                  {provinces.map((province) => {
                    return <option>{province.label}</option>;
                  })}
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  {errors.province}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="mb-3"
                controlId="groupCanton"
                as={Col}
                md="6"
              >
                <Form.Label>Canton</Form.Label>
                <Form.Select
                  aria-label="Canton"
                  value={values.canton}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.canton && touched.canton}
                  disabled={!values.province}
                  name="canton"
                >
                  <option>Seleccione una opcion</option>
                  {filteredCantons.map((canton) => {
                    return <option>{canton.label}</option>;
                  })}
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  {errors.canton}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="groupCanton"
                as={Col}
                md="6"
              >
                <Form.Label>Distrito</Form.Label>
                <Form.Select
                  aria-label="Distrito"
                  value={values.district}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.district && touched.district}
                  disabled={!values.canton}
                  name="district"
                >
                  <option>Seleccione una opcion</option>
                  {filteredDistricts.map((district) => {
                    return <option>{district.label}</option>;
                  })}
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  {errors.district}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Form.Group className="mb-3">
              <Form.Check
                name="termsAndConditions"
                onChange={handleChange}
                isInvalid={!!errors.termsAndConditions}
                label={
                  <span>
                    He leído y acepto los{" "}
                    <a href="#" style={{ color: "#168FCA" }}>
                      Términos y condiciones
                    </a>
                  </span>
                }
                feedback={errors.termsAndConditions}
                feedbackType="invalid"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                name="notificationPermission"
                onChange={handleChange}
                isInvalid={!!errors.notificationPermission}
                label={
                  <span>
                    Deseo recibir novedades exclusivas a mi correo electrónico
                  </span>
                }
                feedback={errors.notificationPermission}
                feedbackType="invalid"
              />
            </Form.Group>
            <Stack direction="horizontal" className="mt-4">
              <Button
                style={{
                  padding: "10px 15px",
                  borderRadius: "15px",
                  minWidth: "150px",
                }}
                disabled={
                  !isNextValid || !isValid || !values.termsAndConditions
                }
                onClick={handleSubmit}
                variant="secondary"
              >
                A la meta <FiArrowRight />
              </Button>
            </Stack>
          </form>
        </Col>
        <Col>
          <div
            className="position-absolute personal-info-form-exercise-girl d-none d-xl-block"
            style={{ width: "35%", top: "20px", right: "20px" }}
          >
            <img
              src={exerciseGirl}
              alt=""
              style={{ borderRadius: "55px", width: "100%" }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UpdateUserProfileForm;
