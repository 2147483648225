import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo, updateUserData } from "../../store/actions/user";

const SelectedInsurer = ({ insurer, bgColor, applyToPlan }) => {
  const isDown1200 = useMediaQuery("(max-width:1200px)");
  const [agePrice, setAgePrice] = useState(0);
  const { plans } = useSelector((state) => state.plans);

  const [insurerPlans, setInsurerPlans] = useState([]);

  useEffect(() => {
    if (insurer) {
      const currentInsurerPlans = plans.filter(
        (planItem) => planItem.insurerCode === insurer.code
      );
      console.log(currentInsurerPlans);

      setInsurerPlans(currentInsurerPlans);
    }
  }, [plans]);

  const { userInfo } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [isElder, setIsElder] = useState(false);
  const [isEldest, setIsEldest] = useState(false);

  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <Card
      className="scroll-container"
      sx={{
        backgroundColor: bgColor,
        pr: isDown1200 ? 0 : 2,
        transform: isDown1200 ? "" : "rotateY(-180deg)",
        zIndex: 400,
        minWidth: isDown1200 ? "100%" : "550px",
        height: "875px",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          height: "100%",
          maxHeight: "875px",
        }}
      >
        <Typography variant="h5" textAlign="center" color="#fff" my={3}>
          Elige tu plan
        </Typography>

        <Grid container>
          {isDown1200 ? null : (
            <Grid item md={4}>
              <Box sx={{ width: "300px", height: "200px" }}>
                <Box
                  component="img"
                  src={insurer?.logo}
                  sx={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                    zIndex: 400,
                    transformOrigin: "center",
                    transform: "rotate(-90deg) translate(-95%, -30%)",
                  }}
                />
              </Box>
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={12} lg={isDown1200 ? 12 : 8}>
            {insurerPlans.map((planItem) => {
              return (
                <Card
                  onClick={() => applyToPlan(planItem)}
                  sx={{
                    mb: 4,
                    borderRadius: "18px",
                    position: "relative",
                    overflow: "visible",
                    cursor: "pointer",
                  }}
                >
                  <CardContent sx={{ pt: "20px", pb: "18px !important" }}>
                    <Typography fontWeight="bold">{planItem.name}</Typography>
                    <ul style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {planItem.data.map((item) => (
                        <li>
                          <small>{item}</small>
                        </li>
                      ))}
                    </ul>

                    <Typography variant="body2">
                      <small>
                        Monto asegurado: ₡
                        {new Intl.NumberFormat("de-DE", {
                          maximumSignificantDigits: 3,
                        }).format(planItem.amount)}
                      </small>
                    </Typography>
                    <Typography variant="body2">
                      <small>
                        {planItem.deductible
                          ? "Con deducible"
                          : "Sin deducible"}
                      </small>
                    </Typography>
                    <Typography variant="body2">
                      <small>Reembolso al {planItem.refundPercentage}</small>
                    </Typography>

                    <Box
                      component="img"
                      src={planItem.badgeImg}
                      alt=""
                      width={{ xs: "60px", sm: "90px" }}
                      sx={{
                        position: "absolute",
                        zIndex: 400,
                        top: -30,
                        right: 0,
                      }}
                    />

                    <Box display="flex">
                      <Typography variant="body2" ml="auto">
                        {!userInfo.birthday ? "Desde" + "  " : ""}
                        <span style={{ fontWeight: "bold" }}>₡ 2750</span> al
                        mes
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              );
            })}
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default SelectedInsurer;
