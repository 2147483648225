import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./store/reducers/authReducer";
import { plansReducer } from "./store/reducers/plansReducer";
import { userReducer } from "./store/reducers/userReducer";
import thunk from "redux-thunk";
import { sponsorsReducer } from "./store/reducers/sponsorsReducer";
import { blogsReducer } from "./store/reducers/blogsReducer";
import { insurersReducer } from "./store/reducers/insurerReducer";

const appReducer = combineReducers({
  auth: authReducer,
  plans: plansReducer,
  user: userReducer,
  sponsors: sponsorsReducer,
  blogs: blogsReducer,
  insurer: insurersReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "[Auth] Logout") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore(
  {
    middleware: [thunk],
    reducer: rootReducer,
  },

  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
