import React, { useEffect } from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { styles } from "../styles/styles";
import { analytics } from "../firebase/firebase-config";

export const PrivacyPage = () => {
  const theme = useTheme();
  useEffect(() => {
    // Log a page view event to Firebase Analytics
    analytics.logEvent("page_view", {
      page_title: "PrivacyPage",
      // You can include additional parameters if needed
    });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, []);
  return (
    <Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            width: "100%",

            [theme.breakpoints.down("md")]: {
              justifyContent: "center",
              minHeight: 0,
            },
          }}
          mt={{ xs: 15, sm: 15, md: 20 }}
          mb={{ xs: 10, sm: 10, md: 20 }}
        >
          <Grid container sx={styles.titleGridContainer}>
            <Box sx={styles.titleCard}>
              <Typography sx={styles.boldTextLight}>
                Política de Privacidad
              </Typography>
            </Box>
          </Grid>
          <Box mt={2} mb={3}>
            <Typography
              mr={"auto"}
              mb={2}
              sx={{ fontSize: "18px", fontWeight: "bold" }}
            >
              Al utilizar nuestra Plataforma Digital, nos confías tus datos.
              Entendemos que es una gran responsabilidad y nos esforzamos al
              máximo para proteger tu información.
            </Typography>
            <Typography mr={"auto"}>
              En este espacio te informamos de forma permanente sobre qué datos
              recogemos, por qué los recogemos y cómo puedes actualizarlos,
              gestionarlos y eliminarlos. All Sports Plus cumple con todos los
              Estándares de Seguridad y SSH para el manejo de datos sensibles
              como información como crediticia.
            </Typography>
          </Box>
          <ol className="termsList">
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms]}>
                1. Información que recolectamos cuándo utilizas nuestros
                servicios
              </Typography>
              <Typography>
                Es importante que sepas que el origen de la información que
                recolectamos dependerá de los servicios de proveedores digitales
                como Google o Apple con los que interactúan tus dispositivos, de
                las aplicaciones que hayas integrado a la Plataforma Digital
                durante el registro y configuración, así como el uso de las
                funcionalidades y el tipo y modo de consumo de los contenidos de
                All Sports Plus.
              </Typography>
              <Typography>
                Esa información, que proviene de diferentes orígenes, nos
                permite ofrecerte contenidos personalizados, ofrecerte mejoras
                en la experiencia de usuario e interfase, así como implementar
                nuevas funciones personalizadas y herramientas de seguridad en
                tu cuenta de All Sports Plus.
              </Typography>
              <Typography>
                La información recolectada por la Plataforma Digital incluye
                identificadores únicos, tiendas de aplicaciones utilizadas, tipo
                de dispositivo y configuración, sistema operativo y versión de
                la aplicación, así como información sobre la interacción de tus
                aplicaciones y dispositivos con nuestra plataforma digital, como
                ciclocomputadores y/o relojes inteligentes que que generan y
                proveen datos durante la sincronización de tu aplicación
                deportiva integrada.
              </Typography>
              <Typography>
                La información se recolecta mientras utilizas All Sports Plus
                así como cuando se establece conexión con proveedores digitales
                como Google, Apple, Samsung, Garmin, Polar, entre otros. Por
                ejemplo, cuando instalas nuestra aplicación desde Google Play o
                App Store, o cuando realizas tu registro de usuario o inicio de
                sesión con tu cuenta de Google o Apple, el dispositivo establece
                conexión periódicamente con los servidores de estos proveedores
                digitales para proporcionar información sobre tu dispositivo y
                la conexión a nuestra Plataforma Digital. En general, nuestra
                Plataforma Digital utiliza diversas tecnologías para recoger y
                almacenar información, incluyendo cookies, etiquetas de píxel,
                almacenamiento local como las cachés de datos de aplicaciones,
                bases de datos y registros de servidor.
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms2]}>
                1.1. Información de tu actividad digital
              </Typography>
              <Typography>
                Recolectamos información sobre tu actividad en nuestros
                servicios para poder recomendarte mejores contenidos digitales,
                implementar nuevas herramientas ajustadas a tus intereses, así
                como generar mejoras de usabilidad enfocadas a tus necesidades.
                Para ello podríamos necesitar información como:​
              </Typography>
              <ol className="termsList">
                <li>
                  ● Los artículos, videos, y demás contenidos digitales que
                  buscas e interactúas en los diferentes módulos de la
                  Plataforma Digital.
                </li>

                <li>
                  ● Las visualizaciones y las interacciones con productos y
                  anuncios en nuestra Plataforma Digital.
                </li>
                <li>
                  ● La actividad de seguimiento deportivo como creación de
                  Objetivos Semanales individuales y grupales, modalidades
                  deportivas seleccionadas y el registro de actividades físico
                  deportivas semanales como el tipo o deporte seleccionado para
                  la actividad, su duración, las calorías activas quemadas
                  durante el ejercicio.
                </li>
                <li>
                  ● Las compras dentro de nuestra Plataforma Digital, así como
                  el tipo, tiempo y modo de uso los beneficios de tu Plan
                  Premium.
                </li>
                <li>
                  ● Los usuarios con los que te comunicas o compartes contenido,
                  así como tipo y modo interacción a través de la Plataforma
                  Digital
                </li>
                <li>
                  ● La actividad en aplicaciones de terceros que utilizan
                  nuestros servicios como Garmin Connect, Polar Flow, Google Fit
                  y Apple Health.
                </li>
              </ol>
              <Typography>
                Es importante que sepas que bajo ningún término All Sports Plus
                recolectará información de llamadas, mensajes SMS, chats de
                mensajería instantánea o bandejas de correo electrónico de tus
                dispositivos integrados. Sí tienes dudas sobre como se gestionan
                los datos de tu proveedor digital y tu cuenta de dispositivo
                consulta su Políticas de Privacidad aquí:
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#1976d2",
                    textDecoration: "none",
                    marginRight: 4,
                  }}
                >
                  Google
                </a>
                o
                <a
                  href="https://www.apple.com/legal/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#1976d2",
                    textDecoration: "none",
                    marginLeft: 4,
                  }}
                >
                  Apple
                </a>
                .
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms2]}>
                1.2. Información de tu ubicación
              </Typography>
              <Typography>
                La información que recolectamos sobre tu ubicación cuando
                utilizas nuestros servicios es importante para poder ofrecerte
                funciones que mejoren tu experiencia digital, como encontrar
                promociones y canjes cerca de donde estés, sincronizar tu
                actividad deportiva, así como brindarte mayor seguridad cuando
                utilizas los servicios de All Sports Plus, ya que nos permite
                asegurarnos que el local físico del comercio donde quieres
                redimir un cupón de canje o un descuento está certificado por
                nuestro equipo comercial.
              </Typography>
              <Typography>
                Para determinar tu ubicación con diferentes niveles de
                precisión, utilizamos herramientas cómo:
              </Typography>
              <ol className="termsList">
                <li>● GPS y otros datos de sensores de tu dispositivo</li>
                <li>● Dirección IP</li>
                <li>
                  ● Actividad en servicios de Google, como las búsquedas que
                  haces y los lugares que etiquetas como tu casa o trabajo
                </li>
                <li>
                  ● Información sobre elementos cercanos a tu dispositivo como,
                  por ejemplo, puntos de acceso Wi-Fi, antenas de servicio de
                  telefonía móvil y/o dispositivos con el Bluetooth activados
                </li>
              </ol>
              <Typography>
                El tipo de datos de ubicación que recogemos y el tiempo durante
                el cual los almacenamos dependen en parte de la configuración de
                tu dispositivo y de tu cuenta. Por ejemplo, puedes activar o
                desactivar la ubicación de tu dispositivo Android o iOS en
                cualquier momento a través de los ajustes de tu dispositivo, así
                como puedes quitar el acceso a tu localización en la
                configuración de permisos otorgados a nuestra aplicación.
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms2]}>
                1.3. Datos de actividad física y salud
              </Typography>
              <Typography>
                All Sports Plus es una plataforma digital enfocada en la salud y
                bienestar integral. Para lograr este objetivo, recolectamos
                ciertos datos personalizados no sensibles sobre tu actividad
                física como las kilocalorías que nos permiten calcular la
                generación de Coins mediante la integración de aplicaciones de
                seguimiento deportivo y salud con All Sports Plus.
              </Typography>
            </Box>

            <Box mt={2} mb={2}>
              <Typography>
                1.3.1. Integración de aplicaciones de seguimiento deportivo y
                salud
              </Typography>
              <Typography mb={2}>
                Al utilizar la aplicación de All Sports Plus brindas tu
                consentimiento para intercambiar datos mediante la pantalla
                "Integración" con la aplicación de seguimiento deportivo y salud
                de tu elección como: Google Fit, Apple Health y Garmin Connect.
                A través de esta integración All Sports Plus puede acceder al
                número de Calorías (kcal) Activas o "Active Calories" consumidas
                o quemadas durante la práctica de una actividad
                físico-deportiva, así como a las Calorías Activas consumidas o
                quemadas durante otras actividades cotidianas siempre y cuando
                estas sean registradas a través aplicación que previamente
                integraste. A través de esa sincronización de datos podemos
                calcular tus Coins, multiplicando cada Caloría Activa reportada
                por un factor constante de conversión de 1,5. ​
              </Typography>
              <Typography mb={2}>
                Para la generación de tus Coins mantendremos acceso de forma
                permanente a estos datos, siempre que tengas activa tu
                Integración. Es importante que sepas que los datos sobre
                Calorías Activas no se comparten con ningún Sponsor,
                Organización o figura externa a All Sports Plus.
              </Typography>
              <Typography mb={2}>
                Para más información sobre como calculamos tus Coins consulta
                nuestro Reglamento sobre la conversión y uso de All Sports Coins
              </Typography>
            </Box>

            <Box mt={2} mb={2}>
              <Typography>
                1.3.2. Datos recolectados durante la Integración por aplicación
              </Typography>
              <Typography sx={{ mt: 2 }}>Google Fit</Typography>

              <ol className="termsList">
                <li>● Calorías activas.</li>
              </ol>

              <Typography sx={{ mt: 2 }}>Apple Health</Typography>

              <ol className="termsList">
                <li>● Calorías activas.</li>
              </ol>
              <Typography sx={{ mt: 2 }}>Garmin Connect </Typography>

              <ol className="termsList">
                <li>● Calorías activas.</li>
              </ol>
            </Box>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms2]}>
                1.4. Personalizar tu experiencia, incluyendo anuncios de
                calidad.
              </Typography>
              <Typography>
                Cómo anteriormente te mencionamos, utilizamos la información que
                recolectamos para ofrecerte una experiencia personalizado, como
                recomendaciones de contenido que pueden interesarte. Por ejemplo
                sí tiene un interés por el ciclismo, nos aseguraremos que puedas
                tener las ultimas novedades sobre los contenidos de Cerebro
                Deportivo enfocados en Ciclismo como próximas carreras, noticias
                deportivas, consejos de expertos para mejorar tu bienestar
                integral.
              </Typography>
              <Typography>
                Según la configuración que establezcas en la sección "Queremos
                Conocerte" junto a la actividad que sincronizas con tu
                Integración y tus comportamientos en la Plataforma Digital,
                podemos mostrarte promociones personalizadas o canjes en función
                de tus intereses. Por ejemplo, si buscas "bicis de montaña",
                puede que te podamos brindar contenidos especializados cuando
                visites el Módulo de Cajes y Promociones o desplegarte
                notificaciones sobre contenidos u ofertas de nuestros Sponsors
                especialistas en el tema.
              </Typography>
              <Typography>
                Es importante que sepas que All Sports Plus es un ambiente libre
                de cualquier tipo de discriminación y que desde su visión
                estructural propicia ser una comunidad de promoción del
                bienestar integral, en pro de tu salud física y mental, por lo
                que:
              </Typography>
              <ol className="termsList">
                <li>
                  ● No te mostremos anuncios personalizados ni contenidos
                  personalizados basados en etnia, religión, orientación sexual
                  o condiciones médicas.
                </li>
                <li>
                  ● No te mostramos anuncios personalizados basados en tu
                  fotografía de perfil.
                </li>
                <li>
                  ● No compartimos información que te identifique personalmente
                  como tu nombre, dirección de correo electrónico con los
                  Sponsors o terceras empresas, salva haya un consentimiento
                  informado.
                </li>
              </ol>
            </Box>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms2]}>
                1.5. Medimos el rendimiento
              </Typography>
              <Typography>
                Utilizamos datos para hacer analíticas y mediciones con el
                objetivo de comprender cómo estás utilizando nuestra Plataforma
                Digital. Por ejemplo, analizamos tus impresiones e interacciones
                con contenidos al momento que navegas y utilizas las facilidades
                de la Plataforma Digital para optimizar el diseño de los
                productos. También utilizamos datos sobre los anuncios con los
                que interactúas a conocer el rendimiento de las campañas
                publicitarias. Para ello, utilizamos diferentes herramientas,
                como Google Analytics. Cuando accedes a nuestro sitio web o usas
                nuestras aplicaciones que utilizan Google Analytics, se puede
                vincular información.
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms2]}>
                1.6. Cookies y otras tecnologías
              </Typography>
              <Typography>
                All Sports Plus podrán emplear cookies y otras tecnologías en la
                Plataforma Digital. Estas tecnologías nos ayudan a entender tus
                intereses, preferencias y necesidades, por ejemplo, para fines
                de seguridad y prevención del fraude. nos indican qué partes de
                nuestras páginas web han visitado y facilitan y miden la
                eficacia de los anuncios publicitarios y las búsquedas en
                internet.
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <ol className="termsList">
                <li>
                  ● Cookies de comunicaciones. Estas cookies se utilizan para
                  habilitar el tráfico de red hasta y desde sistemas de All
                  Sports, por ejemplo, al ayudarnos a detectar cualquier error.
                </li>
                <li>
                  ● Cookies estrictamente necesarias. Estas cookies se
                  determinan como necesarias para proporcionar una prestación o
                  servicio concreto al que has accedido o has solicitado. Por
                  ejemplo, nos permiten mostrar nuestros sitios web en el
                  formato y el idioma correspondientes, autenticar y verificar
                  tus transacciones y conservar el contenido de tu bolsa de la
                  compra cuando adquieres un producto a través de la Plataforma
                  Digital .
                </li>
                <li>
                  ● Cookies estrictamente necesarias. Estas cookies se
                  determinan como necesarias para proporcionar una prestación o
                  servicio concreto al que has accedido o has solicitado. Por
                  ejemplo, nos permiten mostrar nuestros sitios web en el
                  formato y el idioma correspondientes, autenticar y verificar
                  tus transacciones y conservar el contenido de tu bolsa de la
                  compra cuando adquieres un producto a través de la Plataforma
                  Digital
                </li>
                <li>
                  ● No compartimos información que te identifique personalmente
                  como tu nombre, dirección de correo electrónico con los
                  Sponsors o terceras empresas, salva haya un consentimiento
                  informado.
                </li>
              </ol>
              <Typography>
                Si prefieres que All Sports Plus no utilice cookies, te
                proporcionamos los medios para desactivar su uso dentro de
                nuestra Plataforma Digital. Además de las cookies, All Sports
                Plus usa otras tecnologías que nos ayudan a conseguir objetivos
                similares. En algunos de los mensajes que All Sports Plus te
                envía por correo electrónico, se proporciona una «URL de
                pulsación pasante» que te dirige al contenido en el sitio web de
                All Sports Plus. Estas URL, cuando se pulsan, pasan por un
                servidor independiente antes de llegar a la página de destino en
                nuestro sitio web. Realizamos un seguimiento de esa pulsación
                pasante para ayudarnos a determinar el interés en determinados
                temas y medir la eficacia de nuestras comunicaciones contigo. Si
                prefieres que no se te siga de esta manera, no debes hacer clic
                en los enlaces gráficos o de texto en los mensajes enviados por
                correo electrónico.
              </Typography>
              <Typography>
                En All Sports Plus recolectamos algunos datos usando estas
                cookies y tecnologías similares determinados como datos no
                personales. No obstante, en la medida en que la legislación
                local considere las direcciones IP y demás identificadores
                similares como datos personales, también trataremos estos
                identificadores como datos personales en dichas regiones.
                Además, en ocasiones, combinamos datos no personales recogidos
                por medio de estas tecnologías con otros datos personales que
                mantienes. Cuando combinamos los datos de esta manera, tratamos
                los datos combinados como datos personales. ​
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms]}>
                2. Acceder con Google
              </Typography>
              <Typography>
                La función Acceder con Google o "Google Sign In" te ayuda a
                ingresar de forma fácil y segura a tu cuenta de All Sports Plus
                de con tu Cuenta de Google existente.
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography>
                2.1. Que datos utilizamos de tu Cuenta Google
              </Typography>
              <Typography sx={{ mt: 2 }}>
                Cuando utilizas tu Cuenta de Google para registrarte en All
                Sports Plus, Google nos permite acceder a tu nombre, dirección
                de correo electrónico y foto de perfil de tu Cuenta de Google.
                La dirección de correo electrónico se usa para asignar tu
                usuario y como medio de comunicación principal de tu cuenta de
                All Sports Plus. Además, utilizamos algunos datos de tu cuenta
                Google para:
              </Typography>
              <ol className="termsList">
                <li>● Verificar tu correo.</li>
                <li>
                  ● Agregar tu nombre y foto de perfil a tu nueva cuenta en All
                  Sports Plus.
                </li>
                <li>
                  ● Verificar si tu cuenta Google ya ha sido registrada
                  anteriormente en All Sports Plus.
                </li>
              </ol>
              <Typography sx={{ mt: 2 }}>
                Para conocer más detalles sobre como te ayuda Google a compartir
                datos de forma segura con nuestra Plataforma Digital consulta
                <a
                  href="https://developers.google.com/terms/api-services-user-data-policy "
                  target="_blank"
                >
                  aquí.
                </a>
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography>
                2.2. Requisitos de uso limitado del API de Google
              </Typography>
              <Typography sx={{ mt: 2 }}>
                El uso y transferencia de información de All Sports Plus a
                cualquier otra aplicación que sea recibida a través del API de
                Google cumplirá con la Política de datos de usuario de los
                servicios del API de Google, incluidos los requisitos de uso
                limitado. Para más información acerca de los Requisitos de Uso
                Limitado de API de Google consulta aquí. ​{"   "}
                <a
                  href="https://developers.google.com/terms/api-services-user-data-policy "
                  target="_blank"
                >
                  aquí.
                </a>
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms]}>
                3. Protocolo de atención de reclamos sobre la Política de
                Privacidad
              </Typography>
              <ol className="termsList">
                <li>
                  ● Cualquier pregunta, consulta o reclamo en relación a la
                  Política de Privacidad de All Sports Plus deberás canalizarla
                  a través del correo{" "}
                  <a href="mailto:soporte@allsports.plus">
                    soporte@allsports.plus.
                  </a>
                  . Solo se confirmarán comunicaciones que sean enviadas desde
                  el correo electrónico que suministraste durante el proceso del
                  registro en la Plataforma Digital, además, la solicitud debe
                  incluir sin excepción: nombre completo y número de teléfono de
                  contacto. .
                </li>
                <li>
                  ● All Sports Plus resolverá tu pregunta, consulta o reclamo en
                  un plazo máximo de cinco (5) días hábiles, a partir de la
                  fecha en que esta se confirme como recibida por el equipo de
                  Soporte de All Sports Plus.
                </li>
                <li>
                  ● Si no es posible para All Sports Plus responder tu pregunta,
                  consulta o reclamo dentro del plazo establecido por temas de
                  fuerza mayor, All Sports Plus comunicará oportunamente la
                  situación.
                </li>
                <li>
                  ● El consentimiento otorgado, podrá ser revocado en cualquier
                  momento, mediante aviso previo, escrito y firmado dirigido al
                  correo anteriormente mencionado. .
                </li>
              </ol>
              <Typography>
                Si consideras que All Sports Plus no está cumpliendo con las
                obligaciones expuestas en esta Política de Privacidad, es tu
                derecho como usuario digital expresarnos tu disconformidad. Para
                obtener más información, consulta nuestra Política de
                Eliminación de Datos Personales
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms]}>
                4. Cuándo All Sports comparte tu información
              </Typography>
              <Typography>
                No compartimos tu información personal con empresas,
                organizaciones o u otros individuos externos a All Sports Plus
                excepto cuando:
              </Typography>
              <Typography mr={"auto"} sx={[styles.boldTextTerms2]}>
                4.1. Brindes tu consentimiento
              </Typography>
              <Typography>
                Compartiremos tu información personal de forma externa a All
                Sports Plus únicamente si contamos con tu consentimiento. Por
                ejemplo, al registrarte en nuestra aplicación, te pedimos
                permiso con una casilla de verificación con la aceptación de
                Términos y Condiciones donde se establecen las reglas para
                compartir algunos datos no sensibles con terceros. También te
                proporcionamos controles para revisar y gestionar las
                aplicaciones y sitios de terceros a los que has concedido acceso
                a los datos de tu cuenta de All Sports Plus, así mismo, siempre
                te solicitaremos tu consentimiento explícito adicional para
                poder compartir cualquier información personal sensible.
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms2]}>
                4.2. Exista exigencia legal
              </Typography>
              <Typography>
                Si el Departamento Legal de All Sports Plus determina que has
                violado los Términos y Condiciones de la Plataforma Digital,
                existiendo pruebas razonables de uso fraudulento o actividades
                ilegales dentro de la Plataforma Digital, tu información
                personal puede ser compartida a terceros con los siguientes
                propósitos:
              </Typography>
            </Box>

            <ol className="termsList">
              <li>
                ● Cumplir con cualquier requisito previsto en la ley o la
                normativa aplicables o para atender un proceso legal o un
                requerimiento de una autoridad competente.
              </li>
              <li>
                ● Cumplir las condiciones de servicio aplicables, incluida la
                investigación de posibles infracciones.
              </li>
              <li>
                ● Detectar, prevenir o solucionar de otra forma fraudes o
                problemas de seguridad o técnicos.
              </li>
              <li>
                ● Proteger a All Sports Plus, a nuestros clientes, usuarios y al
                público en general de daños a sus derechos y bienes o a su
                seguridad en la medida exigida o permitida por la ley.
              </li>
            </ol>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms2]}>
                4.3. Información personal no sea identificable públicamente
              </Typography>
              <Typography>
                Es posible que compartamos información personal no identificable
                públicamente y con nuestros Sponsors, como anunciantes,
                comercios afiliados, entre otros. Por ejemplo, compartimos
                información públicamente para mostrar las tendencias sobre el
                uso general de nuestros servicios o productos más consumidos.
                También permitimos que determinados Sponsors accedan a
                información de tu para fines de medición y publicitarios para
                los productos y servicios ofertados dentro de la plataforma
                digital. Por ejemplo, artículos canjeados, lugar de canje,
                promociones más buscadas, cupones vencidos, deportes
                seleccionados o tipos de productos de interés
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms]}>
                5. Protección de los datos del usuario
              </Typography>
              <Typography>
                En All Sports Plus, implementamos medidas de seguridad avanzadas
                para proteger la confidencialidad, integridad y disponibilidad
                de tus datos personales. Esto incluye, pero no se limita a:
              </Typography>

              <Typography>
                - Procedimientos de cifrado para garantizar la seguridad de la
                información transmitida y almacenada.
              </Typography>
              <Typography>
                - Acceso restringido a datos personales, limitado únicamente al
                personal autorizado.
              </Typography>

              <Typography>
                - Auditorías regulares para identificar y mitigar posibles
                vulnerabilidades de seguridad.
              </Typography>
              <Typography>
                - Protocolos de seguridad como el uso de redes seguras y
                herramientas de autenticación robusta.
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms]}>
                6. Retención y eliminación de datos
              </Typography>
              <Typography>
                Retenemos tu información personal únicamente durante el tiempo
                necesario para cumplir con los propósitos establecidos en esta
                Política de Privacidad, salvo que la ley requiera o permita un
                período de retención más prolongado. En particular:
              </Typography>

              <Typography>
                - Cuando los datos ya no sean necesarios para los fines para los
                cuales fueron recolectados, serán eliminados o destruidos de
                forma segura..
              </Typography>
              <Typography>
                - Puedes solicitar la eliminación de tus datos personales en
                cualquier momento a través de soporte@allsports.plus.
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms]}>
                7. Vigencia de la Política de Privacidad
              </Typography>
              <Typography>
                Esta Política de Privacidad se encuentra vigente desde el 01 de
                enero de 2023 en su versión PP - 1.0.1 y tus Datos Personales
                permanecerán en la base de datos de All Sports Plus hasta que
                los mismos cumplan con la finalidad para la que fueron
                recolectados.
              </Typography>
              <Typography>
                All Sports Plus como sociedad matriz del Responsable del
                Tratamiento ha definido que sus políticas de privacidad aplican
                para todas sus subordinadas. En caso de inconsistencia o
                contradicción entre lo previsto en esta Políticas de Privacidad
                y otras aplicables, prevalecerá la primera.
              </Typography>
              <Typography>
                Modificamos esta Política de Privacidad de forma periódica. No
                limitaremos los derechos que se te hayan concedido de acuerdo
                con esta Política de Privacidad sin tu consentimiento explícito.
                Siempre te informamos de la fecha de publicación de los últimos
                cambios y te ofrecemos acceso a las versiones anteriores para
                que las puedas consultar. Si los cambios son significativos, te
                lo comunicaremos de forma destacada (por ejemplo, para algunos
                servicios, te enviaremos una notificación de los cambios en la
                Política de Privacidad por correo electrónico).
              </Typography>
              <Typography>
                Al aceptar los términos y condiciones de uso de All Sports Plus,
                complementariamente aceptas nuestras Políticas de Privacidad,
                por tanto, indicas que conoces y autorizas de manera previa,
                expresa e informada a All Sports Plus ASP S.A. cédula jurídica
                3-101-784447, sus filiales y vinculadas, para que tus datos
                personales puedan ser tratados y/o eliminados de acuerdo con lo
                indicado en estas Políticas..
              </Typography>
              <Typography>
                Para más información sobre este tema consulta nuestra
                <a href="/terminos-condiciones " target="_blank">
                  Política de uso de Datos Personales.
                </a>
              </Typography>
            </Box>
          </ol>
          <Typography sx={{ fontWeight: "600", fontStyle: "italic", mt: 10 }}>
            Última actualización - Enero 02, 2025.
          </Typography>
          <Typography sx={{ fontWeight: "600", fontStyle: "italic" }}>
            Versión: PP - 1.0.2
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
