import jwtDecode from "jwt-decode";
import Form from "react-bootstrap/Form";
import { Button, Row, Col } from "react-bootstrap";
import valid from "card-validator"; //import statement
import { useFormik } from "formik";
import * as yup from "yup";

import runner from "../../assests/runner-girl.jpg";

import { number } from "card-validator";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Cards from "react-credit-cards-2";
import visaSymbol from "../../assests/visa_symbol.png";
import amexSymbol from "../../assests/amex_symbol.png";
import { ReactComponent as MastercardSymbol } from "../../assests/mastercard_symbol.svg";

import "./formStyles.scss";
import { useEffect, useState } from "react";
import InsuranceSummary from "../InsuranceSummary/InsuranceSummary";
import PurchaseRaceProgress from "../PurchaseRaceProgress/PurchaseRaceProgress";
import { refreshToken } from "../../store/actions/auth";
import {
  getWalletData,
  setPayment,
  setReSale,
  setSale,
  updateUserData,
} from "../../store/actions/user";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import {
  cardNumberFormatter,
  expirationDateFormatter,
} from "../../utils/Formatters";

const MySwal = withReactContent(Swal);
const iconList = {
  mastercard: <MastercardSymbol style={{ width: "35px", height: "35px" }} />,
  amex: <img src={amexSymbol} alt="card logo" style={{ width: "35px" }} />,
  visa: <img src={visaSymbol} alt="card logo" style={{ width: "35px" }} />,
};

const PurchaseSummaryForm = ({ handleNext, plan, handleBack }) => {
  //USER
  const { user, idToken } = useSelector((state) => state.auth);
  const { userInfo } = useSelector((state) => state.user);
  const [ipAddress, setIPAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const userId = user.uid;

  const theme = useTheme();

  //CARD
  const [declined, setDeclined] = useState(false);
  const [userHasCard, setUserHasCard] = useState(false);
  const [creditCard, setCreditCard] = useState();
  const [cardNumber, setCardNumber] = useState("");
  const [expiration, setExpiration] = useState("");
  const { card } = number(cardNumber);
  const isAmex = card?.type === "american-express";
  const cvvLength = isAmex ? 4 : 3;

  //PLAN
  const total = plan.finalPrice;
  const [basePrice, setBasePrice] = useState(0);
  const [priceNoIVA, setPriceNoIva] = useState(0);
  const [priceToCharge, setPriceToCharge] = useState(0);
  const [apportionmentPrice, setApportionmentPrice] = useState(0);
  const [creditCardsArray, setCreditCardsArray] = useState([]);
  const [maskedCreditCardsArray, setMaskCreditCardsArray] = useState([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState(-1);
  const [agePrice, setAgePrice] = useState(0);

  useEffect(() => {
    if (plan) {
      getPlanPriceByAge(plan); // Calculate price when the plan changes
    }
    console.log(userInfo);
  }, [plan, userInfo]); // Recalculate when plan or userInfo changes

  const getPlanPriceByAge = (plan) => {
    // Ensure userInfo.birthday exists before accessing its properties
    if (!userInfo?.general?.birthday?.seconds) {
      console.log("User birthday is not defined.");
      return; // Exit if birthday is not defined
    }

    const pricesByAgeRange = plan?.pricesByAgeRange; // Ensure plan has pricesByAgeRange
    if (!pricesByAgeRange) return; // Exit if pricesByAgeRange is not defined

    const userBirthday = new Date(userInfo.general?.birthday.seconds * 1000); // Use the birthday's seconds to calculate age
    const today = new Date();
    const ageInMilliseconds = today.getTime() - userBirthday.getTime();
    const age = Math.floor(ageInMilliseconds / (1000 * 60 * 60 * 24 * 365)); // Convert to years

    // Iterate over age range prices to find the correct one
    for (const range of pricesByAgeRange) {
      const { minAge, maxAge, price } = range;

      // Check if the user's age falls within this range
      if (age >= minAge && (maxAge === null || age <= maxAge)) {
        setAgePrice(price); // Update state with the calculated price
        break;
      }
    }
  };

  useEffect(() => {
    // Calculate price without IVA (13%)
    setBasePrice(agePrice);
    setPriceNoIva(basePrice - basePrice * 0.13); // Subtract IVA (13%)
    console.log(agePrice);
    // Calculate total price to charge (including IVA)
    setPriceToCharge((basePrice + basePrice * 0.13 || 0).toFixed(2)); // Adding IVA
  }, [agePrice]);

  useEffect(() => {
    const getCreditCardsFromUser = async () => {
      const wallet = await getWalletData(userId);
      setCreditCardsArray(wallet.creditCards);

      if (wallet && wallet.creditCards) {
        const creditCardFromUser = wallet.creditCards.filter((cc, index) => {
          if (cc.isDefault === true) {
            setSelectedCardIndex(index);
            return true;
          } else {
            return false;
          }
        });

        if (creditCardFromUser.length > 0) {
          setUserHasCard(true);

          const maskedCreditCards = wallet.creditCards.map((card) => {
            if (card.cardNumber) {
              return {
                ...card,
                cardNumber: card.cardNumber
                  .slice(-4)
                  .padStart(card.cardNumber.length, "*"),
              };
            }
            return card; // Ensure card has a valid cardNumber
          });

          setMaskCreditCardsArray(maskedCreditCards);
          setCreditCard(maskedCreditCards[0]);
        }
      }
    };

    getCreditCardsFromUser(); // Call the function when the component is mounted
  }, [userId]);

  const purchaseSummarySchema = creditCard
    ? yup.object().shape({})
    : yup.object({
        cardNumber: yup
          .string()
          .test(
            "test-number",
            "Numero de Tarjeta Inválido",
            (value) => valid.number(value).isValid
          )
          .min(16)
          .max(19, "Numero de Tarjeta Inválido")
          .required("Campo Requerido!"),

        cardExpiration: yup
          .string()
          .test(
            "valid",
            "Formato de fecha no valido",
            (value) => valid.expirationDate(value).isValid
          )
          .required("Campo requerido"),

        cardName: yup
          .string()
          .test(
            "test-name",
            "Nombre Incorrecto",
            (value) => valid.cardholderName(value).isValid
          )
          .required("Campo Requerido!"),
        cardSecurityCode: yup
          .string()
          .matches(/^\d{3,4}$/, "CCV Invalido")
          .required("Campo Obligatorio"),
        termsAndConditions: yup
          .boolean()
          .oneOf([true], "Debe aceptar los terminos antes de continuar"),
      });
  const {
    setFieldValue,
    handleChange,
    touched,
    errors,
    values,
    isValid,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      cardName: "",
      cardNumber: "",
      cardSecurityCode: "",
      cardExpiration: "",
      termsAndConditions: false,
    },
    validationSchema: purchaseSummarySchema,
    onSubmit: (values) => {
      handlePayment(values);
    },
  });

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIPAddress(data.ip))
      .catch((error) => console.log(error));

    console.log(ipAddress);
  }, [ipAddress]);

  const handlePayment = async (values) => {
    const data = {
      cardHoldersName: values.cardName,
      cardNumber: values.cardNumber.replace(/\s/g, ""),
      cardExpiration: values.cardExpiration.replace(/\//g, ""),
      cvv: values.cardSecurityCode,
      amount: 1,
      plan: plan,
      isWeb: true,
      ip: ipAddress,
    };
    MySwal.fire({
      title: "Quieres activar este plan? ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, activar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      const loadingSwal = MySwal.fire({
        title: "Activando...",

        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          MySwal.showLoading();
        },
      });

      if (result.isConfirmed) {
        try {
          if (userHasCard) {
            if (creditCard) {
              if (isValidToken(idToken)) {
                const sale = await setReSale(data, idToken);

                if (sale.ok) {
                  loadingSwal.close();
                  handleNext();
                } else {
                  setDeclined(true);
                  handleRemoveCard();
                }
                loadingSwal.close();
              } else {
                const token = await refreshToken();
                const sale = await setReSale(data, token);
                console.log("sale", sale);

                if (sale.ok) {
                  handleNext();
                } else {
                  setDeclined(true);
                  handleRemoveCard();
                }
                loadingSwal.close();
              }
            } else {
              const token = await refreshToken();
              const res = await setPayment(data, token);
              if (res.ok || res.errorCode === 13) {
                console.log("res sin tarjeta", res);

                const sale = await setSale(data, token);
                if (sale.ok) {
                  // console.log('success');

                  handleNext();
                } else {
                  setDeclined(true);

                  handleRemoveCard();
                }
              }
            }
          } else {
            if (isValidToken(idToken)) {
              console.log("usando idToken");
              const res = await setPayment(data, idToken);
              console.log("res idtoken");
              if (res.ok || res.errorCode === 13) {
                setLoading(true);

                const sale = await setSale(data, idToken);

                if (sale.ok) {
                  handleNext();
                } else {
                  setDeclined(true);

                  handleRemoveCard();
                }
                loadingSwal.close();
              }
            } else {
              console.log("usando refresh token");
              const newToken = await refreshToken();
              const res = await setPayment(data, newToken);
              console.log("res new token", data, newToken, res);

              if (res.ok || res.errorCode === 13) {
                setLoading(true);

                const sale = await setSale(data, newToken);
                // console.log('sale', sale);
                if (sale.ok) {
                  handleNext();
                } else {
                  setDeclined(true);
                  handleRemoveCard();
                }
                loadingSwal.close();
              }
            }
          }
          loadingSwal.close();
        } catch (error) {
          console.log(error);
          loadingSwal.close();
          MySwal.fire("Error", "", "error");
        }

        setLoading(false);
      }

      console.log(values);
    });
  };

  const handleRemoveCard = async () => {
    setLoading(true);
    const wallet = await getWalletData(userId);
    let arrayWithNewCard = [];

    arrayWithNewCard = wallet.creditCards;
    arrayWithNewCard.forEach((card) => {
      card.isDefault = false;
    });

    const privateData = {
      creditCards: arrayWithNewCard,
    };

    await updateUserData(user.uid, null, privateData, "wallet");

    setLoading(false);
  };

  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  return (
    <Row>
      <Col xl="7">
        <PurchaseRaceProgress progressPorcentage={95} />
        <InsuranceSummary
          plan={plan}
          priceToCharge={priceToCharge}
          apportionmentPrice={apportionmentPrice}
          basePrice={basePrice}
        />
        <h4 className="fw-bold my-3">Método de pago</h4>
        <form>
          {!userHasCard && !creditCard ? (
            <>
              <Row>
                <Form.Group
                  className="mb-3"
                  controlId="groupCardCardHolder"
                  as={Col}
                  md="6"
                >
                  <Form.Label>Nombre en la tarjeta</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="cardName"
                    isInvalid={!!errors.cardName && touched.cardName}
                    onBlur={handleBlur}
                    value={values.cardName}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.cardName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="groupCardNumber"
                  as={Col}
                  md="6"
                >
                  <Form.Label>Número de la tarjeta</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(event) => {
                      const text = event.target.value.toString(); // Ensure it's a string
                      const formatted = cardNumberFormatter
                        ? cardNumberFormatter(values.cardNumber, text)
                        : text;
                      setCardNumber(formatted);
                      setFieldValue("cardNumber", formatted);
                    }}
                    name="cardNumber"
                    isInvalid={!!errors.cardNumber && touched.cardNumber}
                    onBlur={handleBlur}
                    value={cardNumber}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.cardNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  className="mb-3"
                  controlId="groupCardCardExpiration"
                  as={Col}
                  md="6"
                >
                  <Form.Label>Fecha de expiración</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(event) => {
                      const text = event.target.value.toString(); // Ensure it's a string
                      const formatted = expirationDateFormatter
                        ? expirationDateFormatter(values.cardExpiration, text)
                        : text;
                      setExpiration(formatted);
                      setFieldValue("cardExpiration", formatted);
                    }}
                    name="cardExpiration"
                    isInvalid={
                      !!errors.cardExpiration && touched.cardExpiration
                    }
                    onBlur={handleBlur}
                    value={expiration}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.cardExpiration}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="groupCardCardExpiration"
                  as={Col}
                  md="6"
                >
                  <Form.Label>CCV</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="cardSecurityCode"
                    isInvalid={
                      !!errors.cardSecurityCode && touched.cardSecurityCode
                    }
                    onBlur={handleBlur}
                    value={values.cardSecurityCode}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.cardSecurityCode}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </>
          ) : null}
          {userHasCard && creditCard ? (
            <Grid container mb={2}>
              <Grid item md={6} ml={-2}>
                {creditCard ? (
                  <Cards
                    name={"----"}
                    number={`---- ---- ----${creditCard.cardNumber}` || ""}
                    expiry={creditCard.expirationDate || ""}
                    cvc={creditCard.cvv || ""}
                    preview={true}
                    issuer={creditCard.issuer || ""}
                  />
                ) : (
                  <Typography variant="body2" color="error">
                    No credit card available.
                  </Typography>
                )}
              </Grid>
              {/* <Grid item md={6} paddingLeft={5}>
                <FormControl fullWidth>
                  <Select
                    onChange={(event) => {
                      const selectedCardNumber = event.target.value;
                      const selectedCard = maskedCreditCardsArray.find(
                        (card) => card.cardNumber === selectedCardNumber
                      );
                      const index =
                        maskedCreditCardsArray.indexOf(selectedCard);
                      setCreditCard(selectedCard);
                      setSelectedCardIndex(index);
                    }}
                    value={creditCard.cardNumber}
                    name="card"
                    error={touched.issuer && Boolean(errors.issuer)}
                  >
                    {maskedCreditCardsArray.map((card) => {
                      return (
                        <MenuItem value={card.cardNumber}>
                          <span style={{ marginRight: "15px" }}>
                            {iconList[card.issuer]}
                          </span>{" "}
                          {`${card.cardNumber}`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid> */}
            </Grid>
          ) : null}
          <Form.Group>
            <Form.Check
              name="billing"
              style={{ fontSize: "12px" }}
              onChange={handleChange}
              isInvalid={!!errors.billing}
              label={<span>Generar factura a mi nombre</span>}
              feedback={errors.billing}
              feedbackType="invalid"
            />
          </Form.Group>

          <Form.Group>
            <Form.Check
              name="notificationPermission"
              style={{ fontSize: "12px" }}
              onChange={handleChange}
              isInvalid={!!errors.notificationPermission}
              label={
                <span>
                  Deseo recibir novedades exclusivas a mi correo electrónico
                </span>
              }
              feedback={errors.notificationPermission}
              feedbackType="invalid"
            />
          </Form.Group>

          <Form.Group>
            <Form.Check
              name="termsAndConditions"
              style={{ fontSize: "12px" }}
              onChange={handleChange}
              isInvalid={!!errors.termsAndConditions}
              label={
                <span>
                  Acepto los{" "}
                  <a href="#" style={{ color: "#168FCA" }}>
                    terminos y condiciones del Plan de Seguro
                  </a>{" "}
                </span>
              }
              feedback={errors.termsAndConditions}
              feedbackType="invalid"
            />
          </Form.Group>

          <Row className="mt-4">
            <Col md="6" sm="12" className="mb-3">
              <Button
                style={{
                  padding: "10px 15px",
                  borderRadius: "15px",
                }}
                onClick={() => {
                  handleBack();
                }}
                variant="primary"
              >
                Atrás
              </Button>
            </Col>
            <Col md="6" sm="12">
              <Button
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: "10px 15px",
                  borderRadius: "15px",
                }}
                disabled={!isValid}
                onClick={() => {
                  handleSubmit();
                }}
                variant="secondary"
              >
                Activar Plan
              </Button>
            </Col>
          </Row>
        </form>
      </Col>
      <Col>
        <div
          className="position-absolute purchase-summary-form-runner d-none d-xl-block"
          style={{ width: "35%", top: "20px", right: "20px" }}
        >
          <img
            src={runner}
            alt=""
            style={{ borderRadius: "55px", width: "100%" }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default PurchaseSummaryForm;
