import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./cardCarousel.scss";

import { NextArrow, PrevArrow } from "./Arrow";
import { useEffect, useRef, useState } from "react";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

const CardCarousel = ({ items, handleShow, component: CardComponent }) => {
  const matchesDown1200 = useMediaQuery("(max-width:1200px)");
  const { supportPhone } = useSelector((state) => state.auth);
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: matchesDown1200 ? <NextArrow /> : null,
    prevArrow: matchesDown1200 ? <PrevArrow /> : null,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const sliderRef = useRef();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (sliderRef.current && matchesDown1200) {
        sliderRef.current?.slickGoTo(1); // Set initial slide to 1
      } else {
        sliderRef.current?.slickGoTo(0); // Set initial slide to 0
      }
    }, 100); // Delay for 100ms

    return () => clearTimeout(timeoutId);
  }, [sliderRef, matchesDown1200, items]);

  return (
    <Slider {...settings} ref={sliderRef} className="insurer-overflow">
      {items.map((item, index) => {
        return (
          <CardComponent
            item={item}
            index={index}
            handleShowLogIn={handleShow}
            supportPhone={supportPhone}
          />
        );
      })}
    </Slider>
  );
};

export default CardCarousel;
