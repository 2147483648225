import { useKeenSlider } from "keen-slider/react";

import { useEffect, useRef, useState } from "react";
import { getAllPlans } from "../../store/actions/plans";
import { useDispatch, useSelector } from "react-redux";

import darkCoin from "../../assests/darkCoin.gif";
import aflogoCarousel from "../../assests/afLogoCarousel.png";

import circle from "../../assests/yellowCircle.png";
import "./plans.scss";
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import InsurerCard from "./InsurerCard";
import { getAllInsurers } from "../../store/actions/insurer";
import PlanCard from "./PlanCard";
import CardCarousel from "./CardCarousel";

const Plans = ({ handleShow }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isFirstUse = useRef(true);

  const location = useLocation();

  const plans = useSelector((state) => state.plans.plans);
  const insurers = useSelector((state) => state.insurer.insurers);

  const [showAgedPeoplePlan, setShowAgedPeoplePlan] = useState(false);
  const [elderlyPlan, setElderlyPlan] = useState(null);
  const [insurersData, setInsurersData] = useState([]);
  const [loaded, setLoaded] = useState(false); // This can be used to track data load
  const [loading, setLoading] = useState(true); // Track the loading state
  const [currentSlide, setCurrentSlide] = useState(0);

  const getPlans = () => {
    dispatch(getAllPlans());
  };

  const getInsurers = () => {
    dispatch(getAllInsurers());
  };

  useEffect(() => {
    const plan = plans.find((plan) => plan.isElderPlan);
    setElderlyPlan(plan);
  }, [plans]);

  useEffect(() => {
    if (insurers) {
      const copy = insurers.slice();
      copy.splice(1, 0, { aflogo: aflogoCarousel });
      setInsurersData(copy);
    }
  }, [insurers]);

  useEffect(() => {
    if (isFirstUse.current) {
      getPlans();
      getInsurers();
      isFirstUse.current = false;
    }
  }, []); // Runs once after component mount

  // Ensure loading state is updated when data is fetched
  useEffect(() => {
    if (plans.length > 0 && insurers.length > 0) {
      setLoading(false); // Data is loaded
    }
  }, [plans, insurers]);
  const maxWidth = location.pathname === "/profile/plans" ? "false" : "lg";
  const togglePlans = () => {
    setShowAgedPeoplePlan((prevValue) => !prevValue);
  };

  if (loading) {
    return (
      <Container maxWidth={maxWidth} sx={{ px: 0 }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: location.pathname === "/profile/plans" ? 2 : 8,
            mb: location.pathname === "/profile/plans" ? 2 : 8,
            [theme.breakpoints.down("sm")]: {
              width: "50%",
              padding: 0,
            },
          }}
          p={{ xs: 2, sm: 2, md: 4 }}
        >
          <h3>Cargando...</h3>
          <img
            src={darkCoin}
            alt="Moneda girando"
            style={{ width: "150px", height: "150px" }}
          />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth={maxWidth} sx={{ px: 0 }}>
      {location.pathname === "/profile/plans" ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: location.pathname === "/profile/plans" ? 2 : 8,
            mb: location.pathname === "/profile/plans" ? 2 : 8,
            [theme.breakpoints.down("sm")]: {
              width: "50%",
              padding: 0,
            },
          }}
          p={{ xs: 2, sm: 2, md: 4 }}
        >
          <Typography
            variant="h3"
            color={theme.palette.dark.terciary}
            sx={{
              fontWeight: "bold",
              [theme.breakpoints.down("sm")]: {
                fontSize: 24,
              },
            }}
          >
            Planes
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "30%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: location.pathname === "/profile/plans" ? 2 : 8,
            mb: location.pathname === "/profile/plans" ? 2 : 8,
            [theme.breakpoints.down("sm")]: {
              width: "50%",
            },
          }}
          p={{ xs: 2, sm: 2, md: 4 }}
        >
          <Typography
            variant="h3"
            color={theme.palette.dark.terciary}
            sx={{
              fontWeight: "bold",
              [theme.breakpoints.down("sm")]: {
                fontSize: 24,
              },
            }}
          >
            Planes
          </Typography>
          <Box
            component="img"
            sx={{
              height: "48px",
              width: "48px",
            }}
            alt="The house from the offer."
            src={circle}
          />
        </Box>
      )}

      {insurersData && (
        <CardCarousel
          items={insurersData}
          component={InsurerCard}
          handleShow={handleShow}
        />
      )}

      {/* Add logic to handle toggle plans */}
    </Container>
  );
};

export default Plans;
