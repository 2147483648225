import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import heroBackground from "../../assests/bg-hero.png";
import appExample from "../../assests/hero.png";
import playstore from "../../assests/playstore.png";
import apple from "../../assests/apple.png";
import "./hero.scss";

import { getAllOverallCoins } from "../../store/actions/sponsors";

const Hero = ({ theme, loggedIn, user }) => {
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setloading] = useState(true);
  const [count, setCount] = useState(0);
  const [coins, setCoins] = useState({
    overallSportCoins: 0,
    multiplier: 0.1,
  });
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    const fetchOverallSportCoinsSum = async () => {
      try {
        const data = await getAllOverallCoins();

        setCoins(data);
        setCount(data.overallSportCoins || 0); // Default to 0 if undefined
        setDataFetched(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setCount(0); // Set a default value in case of an error
      } finally {
        setTimeout(() => {
          setloading(false);
        }, 1000);
      }
    };

    if (!dataFetched) {
      fetchOverallSportCoinsSum();
    }

    const interval = setInterval(() => {
      setCount((prevCount) => prevCount + coins.multiplier);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dataFetched]);

  const handleApple = () => {
    window.open(
      "https://apps.apple.com/us/app/all-sports/id1625764526",
      "_blank"
    );
  };
  const handleAndroid = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=plus.allsports.app",
      "_blank"
    );
  };

  return (
    <div className="hero-bg-img">
      <Box width="100%" sx={{ height: { xs: "85px", xl: 0 } }}></Box>
      <Container
        maxWidth="xl"
        sx={{
          overflow: "hidden",
          minHeight: "75vh",
          height: { xs: "auto", xl: "75vh" },
          [theme.breakpoints.down("sm")]: {
            minHeight: "750px",
          },
        }}
      >
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={{ md: "center", xs: "flex-start", lg: "center" }}
          sx={{
            height: "100%",
          }}
        >
          <Grid
            item
            container
            xs={12}
            md={6}
            lg={6}
            p={4}
            sx={{
              [theme.breakpoints.down("sm")]: {
                pr: 1,
                pl: 1,
              },
            }}
          >
            <Grid item className="side-text">
              <Typography
                variant={"body1"}
                style={{
                  color: theme.palette.accent.main,
                  fontWeight: "600",
                  fontSize: 18,
                }}
              >
                Bienvenidos
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: "bold",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: 40,
                  },
                }}
                color={"white"}
              >
                ¡Transforma
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: "bold",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: 40,
                  },
                }}
                color={theme.palette.accent.main}
              >
                tus calorías!
              </Typography>

              <Box>
                <Typography
                  variant="body1"
                  color={"white"}
                  sx={{
                    width: "75%",
                    mt: 2,
                    fontSize: 14,
                    [theme.breakpoints.down("sm")]: {
                      width: "100%",
                      fontSize: 15,
                    },
                  }}
                >
                  Total de calorías transformadas
                </Typography>
                {loading ? (
                  <Box
                    sx={{
                      height: 48,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress color="secondary" size={18} />
                  </Box>
                ) : (
                  <Typography
                    variant="body1"
                    color={"white"}
                    sx={{
                      width: "75%",

                      fontSize: 32,
                      fontWeight: "800",
                      [theme.breakpoints.down("sm")]: {
                        width: "100%",
                        fontSize: 18,
                        fontWeight: "800",
                      },
                    }}
                  >
                    {count.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                )}
              </Box>

              <Typography
                variant="body1"
                color={"white"}
                sx={{
                  width: "75%",
                  mt: 2,
                  fontSize: 18,
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                    fontSize: 14,
                  },
                }}
              >
                Descubre cómo tus hábitos saludables pueden tener un impacto
                positivo en tu vida.
              </Typography>
            </Grid>

            <Box
              mt={{ md: loggedIn ? 8 : 16, xs: 1 }}
              sx={{
                width: "60%",
                [theme.breakpoints.down("lg")]: {
                  width: "100%",
                },
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold" }}
                color={"white"}
              >
                ¡Descárgala gratis!
              </Typography>
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
                justifyContent={{
                  sm: "flex-start",
                  md: "space-between",
                }}
                mt={3}
              >
                <Grid item md={6} mb={2}>
                  <Box
                    component="img"
                    sx={{
                      position: "relative",
                      width: "150px",
                      cursor: "pointer",
                      zIndex: 3,
                      [theme.breakpoints.down("md")]: {
                        width: "115px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        width: "100px",
                      },
                    }}
                    alt="The house from the offer."
                    src={playstore}
                    onClick={handleAndroid}
                    mr={{ sm: 6, xs: 2 }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Box
                    component="img"
                    sx={{
                      position: "relative",
                      cursor: "pointer",
                      zIndex: 3,
                      width: "150px",
                      [theme.breakpoints.down("md")]: {
                        width: "115px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        width: "100px",
                      },
                    }}
                    alt="The house from the offer."
                    onClick={handleApple}
                    src={apple}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              height: "100%",
              [theme.breakpoints.down("sm")]: {
                height: "100%",
              },
            }}
          >
            {matches ? (
              <Box
                component="img"
                sx={{
                  width: "auto",
                  maxHeight: "600px",
                  position: "absolute",
                  bottom: 0,

                  [theme.breakpoints.down("md")]: {
                    position: "absolute",
                    bottom: 0,
                    maxHeight: "350px",
                  },
                }}
                alt="The house from the offer."
                src={appExample}
              />
            ) : (
              // </Grid>
              <Box
                component="img"
                sx={{
                  //position: "absolute",
                  right: 0,
                  bottom: 0,
                  maxHeight: "450px",
                  zIndex: 0,
                  [theme.breakpoints.down("sm")]: {
                    position: "absolute",
                    margin: "0 auto",
                    bottom: 0,
                    // top: 50%;
                    left: "50%",
                    transform: "translate(-50%, -0%)",
                    maxHeight: "350px",
                  },
                }}
                alt="The house from the offer."
                src={appExample}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Hero;
