import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Divider,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styles } from "../../styles/styles";
import { getAllPlans } from "../../store/actions/plans";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { handleWhatsapp } from "../../utils/Links";

const MySwal = withReactContent(Swal);

const Billing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { userInfo } = useSelector((state) => state.user);
  const plans = useSelector((state) => state.plans.plans);
  const { supportPhone } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [agePrice, setAgePrice] = useState(0); // Price based on user's age
  const [priceNoIVA, setPriceNoIva] = useState(0); // Price without IVA
  const [priceToCharge, setPriceToCharge] = useState(0); // Final price with IVA

  useEffect(() => {
    if (userInfo && plans.length > 0) {
      // Find the selected plan based on userInfo
      const userPlan = plans.find(
        (plan) => plan.code === userInfo.userPlan.code
      );
      if (userPlan) {
        setSelectedPlan(userPlan);
        setLoading(false); // Set loading to false once the plan is set
      } else {
        // Handle case where no plan is found
        setLoading(false);
      }
    }
  }, [userInfo, plans]);

  useEffect(() => {
    if (selectedPlan) {
      getPlanPriceByAge(selectedPlan); // Calculate the plan price when selectedPlan is set
    }
  }, [selectedPlan, userInfo]);

  const getPlanPriceByAge = (plan) => {
    const pricesByAgeRange = plan?.pricesByAgeRange;
    if (!pricesByAgeRange || !userInfo?.general?.birthday) return;

    const userBirthday = new Date(userInfo.general.birthday.seconds * 1000);
    const today = new Date();
    const ageInMilliseconds = today.getTime() - userBirthday.getTime();
    const age = Math.floor(ageInMilliseconds / (1000 * 60 * 60 * 24 * 365));

    for (const range of pricesByAgeRange) {
      const { minAge, maxAge, price } = range;

      // Check if the user's age falls within this range
      if (age >= minAge && (maxAge === null || age <= maxAge)) {
        setAgePrice(price); // Update state with the calculated price
        break;
      }
    }
  };
  console.log(agePrice);
  useEffect(() => {
    if (agePrice) {
      setPriceNoIva(agePrice - agePrice * 0.13); // Subtract IVA (13%) from base price
      setPriceToCharge((agePrice + agePrice * 0.13).toFixed(2)); // Add IVA (13%)
    }
  }, [agePrice]);

  if (loading) {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "450px",
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (!selectedPlan) {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "450px",
        }}
      >
        <Typography variant="h6">No plan found for the user.</Typography>
      </Grid>
    );
  }

  return (
    <Box sx={styles.formContainer}>
      <Box>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Mi Plan
        </Typography>
      </Box>
      <Box mt={2} mb={4}>
        <Typography variant="body1" sx={{ fontWeight: "400" }}>
          Actualmente tu Plan Premium disfrutas de coberturas y asistencias
          médicas 24/7, canjes y muchos más beneficios para tu bienestar
          integral.
        </Typography>
      </Box>
      <Grid container columnGap={2} justifyContent={"flex-start"}>
        <Grid item lg={4} md={5} container mb={3} sx={styles.summaryCard}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6">
              {selectedPlan.name || selectedPlan.subTier || "N/A"}
            </Typography>
            <Typography variant="body1">
              Código: {selectedPlan.code || "N/A"}
            </Typography>
            <Grid container sx={{ margin: "0 auto", mt: 2, pt: 1, pb: 1 }}>
              <Box mb={1}>
                <Typography variant="body1">Detalles del Plan</Typography>
              </Box>
              {selectedPlan.data?.map((data, index) => (
                <Grid container item xs={12} key={index}>
                  <Grid item xs={8}>
                    <Typography
                      textAlign={"left"}
                      variant="body1"
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {data.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" textAlign={"right"}>
                      {data.value}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={4} md={5} container mb={3} sx={styles.summaryCard}>
          <Typography variant="h6" sx={{ fontSize: "16", fontWeight: "bold" }}>
            Resumen de Compra
          </Typography>
          <Grid container item xs={12}>
            <Grid item xs={8}>
              <Typography
                textAlign={"left"}
                variant="body1"
                sx={{ fontSize: "0.8rem" }}
              >
                Plan
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" textAlign={"right"}>
                {selectedPlan.name || selectedPlan.subTier || "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={8}>
              <Typography
                textAlign={"left"}
                variant="body1"
                sx={{ fontSize: "0.8rem" }}
              >
                Monto
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" textAlign={"right"}>
                {priceNoIVA ? priceNoIVA : 0}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={8}>
              <Typography
                textAlign={"left"}
                variant="body1"
                sx={{ fontSize: "0.8rem" }}
              >
                IVA
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" textAlign={"right"}>
                {agePrice ? (agePrice * 0.13).toFixed(2) : 0}
              </Typography>
            </Grid>
          </Grid>

          <Divider component="span" sx={{ width: "100%", mb: 2 }} />
          <Grid container item xs={12}>
            <Grid item xs={8}>
              <Typography
                textAlign={"left"}
                variant="body1"
                sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
              >
                Total
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" textAlign={"right"}>
                {priceToCharge >= 0 ? priceToCharge : 0}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="body2" sx={{ mt: 2 }}>
          El pago de tu plan {selectedPlan.subTier} vence {new Date().getDate()}
          /{new Date().getMonth() + 1}/{new Date().getFullYear()}.
        </Typography>
        <Typography variant="body2" sx={{}}>
          Al comprar este Plan Premium autorizas su recurrencia mensual cada
          primer día del mes en curso por un total de {selectedPlan.finalPrice}{" "}
          I.V.A.I. por mes. Para más detalles sobre las condiciones de la compra
          consulta{" "}
          <a href="allsports.plus" style={{ color: "black" }}>
            aquí.
          </a>
        </Typography>
      </Box>

      <Grid
        container
        columnGap={0}
        justifyContent={"space-between"}
        alignItems={"center"}
        mt={3}
      >
        <Grid item md={6} container mb={3}>
          <Typography
            component={"a"}
            sx={{ color: theme.palette.primary.main }}
            onClick={() => handleWhatsapp(supportPhone)}
          >
            ¿Necesitas ayuda?
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Billing;
