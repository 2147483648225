import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Box,
  useTheme,
} from "@mui/material";

import DialogWithSteps from "../modals/PurchaseInsurance";
import SignInModal from "../modals/SignInModal";
import RegisterModal from "../modals/RegisterModal";
import { useSelector } from "react-redux";
import SelectedInsurerModal from "../modals/SelectedInsurerModal";

import asPremiumRibbon from "../../assests/PREMIUM (2) 8.png";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

const InsurerCard = ({ item: insurer, handleShowLogIn }) => {
  const theme = useTheme();

  const [idNumber, setIdNumber] = useState("");
  const [open, setOpen] = useState(false);
  const [openPayPlan, setOpenPayPlan] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const user = useSelector((state) => state.auth.user);
  const { userInfo } = useSelector((state) => state.user);
  const { supportPhone } = useSelector((state) => state.auth);

  const handleOpenLogIn = (val) => setShow(val);
  const whatsappLink = `https://wa.me/${supportPhone}`;

  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * If the user is logged in, open the DialogWithSteps to purchase
   * the selected insurance plan. If the user is not logged in, open
   * the SignInModal to allow the user to log in or sign up.
   */
  /******  d310882c-0070-49a9-9405-3375cb4d5525  *******/
  const applyToPlan = () => {
    if (user) {
      setOpen(true);
    } else {
      handleShowLogIn(true);
    }
  };
  useEffect(() => {
    if (!insurer.isActive) {
      setDisabled(true);
    }
  }, [insurer]);

  if (insurer.aflogo) {
    return (
      <Card
        sx={{
          minHeight: "547px",
          maxHeight: "547px",
          mx: "auto",
          maxWidth: "320px",
          width: "100%",
          borderRadius: "18px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          mb: 3,
          padding: "20px",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={insurer.aflogo}
            alt="AF seguros logo"
            width="85%"
          />
          <Typography variant="body1" fontWeight="bold" mt={5}>
            Elegí una aseguradora
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const openAlert = () => {
    MySwal.fire({
      input: "text",
      inputLabel: "Ingrese su número de cédula",
      inputValue: idNumber,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      inputValidator: (value) => {
        if (!value || isNaN(value)) {
          return "Valor no valido";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setIdNumber(result.value);
        MySwal.fire(`Cedula: ${result.value}`);
      }
    });
  };

  return (
    <Card
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
        minHeight: "547px",
        maxHeight: "547px",
        mx: "auto",
        maxWidth: "320px",
        width: "100%",
        borderRadius: "18px",
        display: "flex",
        position: "relative",
        overflow: "visible",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "20px",
        mb: 3,
        "&:hover": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",

          "& .apply-button": {
            backgroundColor: disabled ? "grey" : theme.palette.primary.main,
            color: theme.palette.primary.secondary,
          },
        },
      }}
    >
      <CardContent sx={{ overflow: "visible" }}>
        {" "}
        {insurer?.logo ? (
          <img src={insurer.logo} alt="Logo de aseguradora" width="100%" />
        ) : (
          <Typography
            variant="h4"
            align="center"
            paragraph
            sx={{ fontSize: 38, mb: 0, fontWeight: "500" }}
          >
            {insurer?.name}
          </Typography>
        )}
        <img
          src={asPremiumRibbon}
          alt=""
          width="70px"
          style={{ position: "absolute", right: 0, top: -35 }}
        />
      </CardContent>
      <CardContent className="d-flex flex-column" sx={{ pt: 0 }}>
        {/* <Typography
          variant="h4"
          align="center"
          paragraph
          sx={{ fontSize: 38, fontWeight: "800" }}
        >
          {plan.subTier}
        </Typography> */}

        <Typography textAlign="center">{insurer?.description}</Typography>

        <div>
          {/* <div className="w-50 mx-auto my-3">
            <img
              src={afSegurosLogo}
              alt="af seguros logo"
              className="d-block mx-auto logoimg"
            />
          </div> 
          //{!agedPlan ? null : (
            // <Typography
            //   variant="body1"
            //   align="center"
            //   sx={{
            //     cursor: "auto",
            //     color: "#8778e4",
            //   }}
            //   onClick={() => window.open(whatsappLink, "_blank")}
            // >
            //   {`₡${plan.finalPrice} por mes`}
            // </Typography>
            // <Box sx={{ display: "flex", justifyContent: "center" }}>
            //   <Typography
            //     component="a"
            //     align="center"
            //     sx={{
            //       cursor: "pointer !important",
            //       color: "#8778e4",
            //     }}
            //     onClick={() => window.open(whatsappLink, "_blank")}
            //   >
            //     Consulta aquí
            //   </Typography>
            // </Box>
          // )} */}
        </div>
      </CardContent>

      <CardActions sx={{ mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            mx: "auto",
          }}
        >
          <Typography variant="body2">Planes desde ₡1750</Typography>
          <Button
            variant={"contained"}
            //className="apply-button" // Add a class to the button
            sx={{
              backgroundColor: theme.palette.primary.main,
              width: "100%",

              width: "100%",
              borderRadius: "42px",
              pt: 1.5,
              pb: 1.5,
              mx: "auto",
              mt: 1,
              "&:hover": {
                // Define the styles for the button on hover here
                // For example, you can change the background color or text color
                backgroundColor: theme.palette.secondary.main,
                //color: "#EFF2FB",
                //opacity: 0.9,
              },
            }}
            disabled={disabled} // Set the disabled prop here
            onClick={applyToPlan}
          >
            <Typography variant="body1" sx={{ fontWeight: "600" }}>
              ¡Quiero esta!
            </Typography>
            {/* {!agedPlan ? (
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                ¡Quiero esta!
              </Typography>
            ) : (
              <Typography
                variant="body1"
                sx={{ fontWeight: "600" }}
                onClick={() => window.open(whatsappLink, "_blank")}
              >
                Aplicar Plan
              </Typography>
            )} */}
          </Button>
        </Box>
      </CardActions>

      <DialogWithSteps
        open={openPayPlan}
        setOpen={setOpenPayPlan}
        plan={selectedPlan}
        openLogIn={handleOpenLogIn}
      />

      <SelectedInsurerModal
        show={open}
        handleClose={() => setOpen(false)}
        insurer={insurer}
        setOpenPayPlan={setOpenPayPlan}
        setPlan={setSelectedPlan}
      />

      <RegisterModal
        open={openSignUp}
        setOpen={setOpenSignUp}
        openLogIn={setShow}
      />

      <SignInModal
        handleClose={handleOpenLogIn}
        show={show}
        setOpenSignUp={setOpenSignUp}
      />
    </Card>
  );
};

export default InsurerCard;
