import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Container } from "react-bootstrap";

import { Box, useMediaQuery } from "@mui/material";

import SelectedInsurer from "../selectedInsurer/SelectedInsurer";
import { IoMdClose } from "react-icons/io";
import "./selectedPlanAccordion.scss";

const SelectedInsurerAccordion = ({
  selectedInsurer,
  setOpenPayPlan,
  setPlan,
  handleClose,
}) => {
  const [activeTab, setActiveTab] = useState(1);

  const { insurers } = useSelector((state) => state.insurer);

  const isDown1300 = useMediaQuery("(max-width:1300px)");

  const handleTabClick = (index) => {
    setActiveTab((prevActiveTab) => (prevActiveTab === index ? null : index));
  };

  const applyToPlan = (plan) => {
    handleClose(false);
    setOpenPayPlan(true);
    setPlan(plan);
  };

  let isFirstColor = true;

  const accordionData = insurers.map((currentInsurer) => {
    let nextColor = "";

    if (isFirstColor) {
      nextColor = "#3E3288";
      isFirstColor = !isFirstColor;
    } else {
      nextColor = "#625EA8";
      isFirstColor = !isFirstColor;
    }

    return (
      <SelectedInsurer
        bgColor={nextColor}
        insurer={currentInsurer}
        applyToPlan={applyToPlan}
      />
    );
  });

  useEffect(() => {
    const selectedInsurerIndex = insurers.findIndex(
      (insurerItem) => insurerItem.id === selectedInsurer.id
    );
    setActiveTab(selectedInsurerIndex);
  }, []);

  //This gives the name to the panels or tabs
  const getCurrentName = (index) => {
    const currentInsurer = insurers[index];

    return (
      <Box sx={{ width: "300px", height: "200px", marginBottom: 0 }}>
        <img
          src={currentInsurer?.logo}
          alt="Logo de aseguradora"
          style={{ objectFit: "contain", width: "100%", height: "100%" }}
        />
      </Box>
    );
  };

  return (
    <Container
      style={{
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "center",
        transform: "rotateY(180deg)",
      }}
    >
      <div
        className="accordion d-flex justify-content-center"
        style={{ backgroundColor: "transparent" }}
      >
        {accordionData.map((tab, index) => (
          <div
            style={
              index === 0
                ? {
                    backgroundColor: "#3E3288",
                    borderRadius: "55px 0px 0px 55px",
                    padding: "15px",
                    left: -25,
                    zIndex: 300,
                    cursor: activeTab === index ? "default" : "pointer",
                    boxShadow: "9px 4px 34px 0px rgba(0, 0, 0, 0.25)",
                  }
                : index === 1
                ? {
                    backgroundColor: "#625EA8",
                    borderRadius: "0 55px 55px 0",
                    padding: "15px",
                    left: -145,
                    zIndex: 200,
                    cursor: activeTab === index ? "default" : "pointer",
                    boxShadow: "8px 4px 27px 0px rgba(0, 0, 0, 0.25)",
                  }
                : {
                    backgroundColor: "#625EA8",
                    borderRadius: "55px 55px 55px 55px",
                    padding: "15px",
                    position: "relative",
                    left: -25,
                    zIndex: 100,
                    boxShadow: "9px 4px 34px 0px rgba(0, 0, 0, 0.25)",
                  }
            }
            className={`tab d-flex align-items-center ${
              activeTab === index ? "active show" : ""
            }`}
            key={index}
            onClick={(event) => {
              if (activeTab === index) {
                event.stopPropagation(); // ignore the click event
              } else {
                handleTabClick(index);
              }
            }}
          >
            <div
              style={isDown1300 ? { width: "100px" } : { width: "225px" }}
            ></div>
            <div
              className={`text-white text-center ${
                activeTab === index ? "d-none" : ""
              }`}
              style={{
                margin: "auto",
                transformOrigin: "center",
                transform:
                  "rotateY(-180deg) rotateZ(-90deg) translate(-10%, 35%)",
              }}
            >
              {getCurrentName(index)}
            </div>
            {activeTab === index && <>{tab}</>}
          </div>
        ))}
      </div>
    </Container>
  );
};

export default SelectedInsurerAccordion;
