import { Box } from "@mui/material";
import { useFormik } from "formik";
import { cardAdministrationSchema } from "../../schemas/cardAdministrationSchema";

import { useCallback, useEffect, useState } from "react";
import { styles } from "../../styles/styles";
import { getWalletData, updateUserData } from "../../store/actions/user";
import { useSelector } from "react-redux";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CardList from "../cardList/CardList";
import AddCardForm from "../forms/AddCardForm";

const MySwal = withReactContent(Swal);

const issuerList = [
  {
    label: "Visa",
    value: "visa",
  },
  {
    label: "Mastercard",
    value: "mastercard",
  },
  {
    label: "American Express",
    value: "american-express",
  },
  {
    label: "Diners Club",
    value: "diners-club",
  },
];

const CardAdministration = () => {
  const [cardNumber, setCardNumber] = useState("");
  const [expiration, setExpiration] = useState("");
  const [creditCardsArray, setCreditCardsArray] = useState([]);
  const [maskedCreditCardsArray, setMaskCreditCardsArray] = useState([]);

  const { userInfo } = useSelector((state) => state.user);

  const getCreditCardsFromUser = useCallback(async () => {
    const wallet = await getWalletData(userInfo.userId);

    if (wallet.creditCards?.length) {
      const copyArr = wallet.creditCards.slice();

      copyArr.sort((a, b) => {
        if (a.isDefault && !b.isDefault) return -1;
        if (!a.isDefault && b.isDefault) return 1;
        return 0;
      });

      const maskedCreditCards = copyArr.map((card) => {
        return {
          ...card,
          cardNumber: card.cardNumber
            .slice(-4)
            .padStart(card.cardNumber.length, "*"),
        };
      });

      setCreditCardsArray(copyArr);
      setMaskCreditCardsArray(maskedCreditCards);
    }
  }, []);

  const handleRefresh = () => {
    getCreditCardsFromUser();
  };

  useEffect(() => {
    getCreditCardsFromUser();
  }, [userInfo]);

  const showAlert = (msg, userId, privateData, type, executeAction) => {
    MySwal.fire({
      title: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      const loadingSwal = MySwal.fire({
        title: "Guardando...",
        html: '<div class="swal2-spinner"></div>',
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      if (result.isConfirmed) {
        try {
          await updateUserData(userId, undefined, privateData, type);
          if (executeAction) {
            executeAction();
          }

          handleRefresh();
        } catch (error) {
          console.log(error);
          MySwal.fire("Error", "", "error");
        }
      }

      loadingSwal.close();
    });
  };

  const formik = useFormik({
    initialValues: {
      cardName: "",
      cardNumber: "",
      cardSecurityCode: "",
      cardExpiration: "",
      issuer: issuerList[0].value,
      issuerByCardNumber: "",
    },
    validationSchema: cardAdministrationSchema,
    onSubmit: (values) => {
      const exist = creditCardsArray.some(
        (card) => card.cardNumber === values.cardNumber.replace(/\s/g, "")
      );

      if (exist) {
        MySwal.fire("Error", "La tarjeta ya existe", "error");
        return;
      }

      const newCard = {
        //cardHoldersName: values.cardName,
        cardNumber: values.cardNumber.replace(/\s/g, ""),
        expirationDate: values.cardExpiration.replace(/\//g, ""),
        cdate: new Date(),
        //cvv: values.cardSecurityCode,
        isDefault: false,
        //issuer: values.issuer,
      };

      const creditCards = [...creditCardsArray, newCard];

      const data = { creditCards };

      const executeAfterUpdate = () => {
        formik.handleReset();
        setExpiration("");
        setCardNumber("");
      };

      showAlert(
        "Agregar una nueva tarjeta?",
        userInfo.userId,
        data,
        "wallet",
        executeAfterUpdate
      );
    },
  });

  return (
    <>
      <Box sx={styles.formContainer}>
        <AddCardForm
          formik={formik}
          setCardNumber={setCardNumber}
          setExpiration={setExpiration}
          expiration={expiration}
          cardNumber={cardNumber}
        />
      </Box>

      <CardList
        maskedCreditCardsArray={maskedCreditCardsArray}
        creditCardsArray={creditCardsArray}
        showAlert={showAlert}
      />
    </>
  );
};

export default CardAdministration;
