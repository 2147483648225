import { db } from "../../firebase/firebase-config";
import { types } from "../types/types";

export const SET_USER_INFO = "SET_USER_INFO";

export const getAllInsurers = () => {
  return async (dispatch) => {
    const data = await db.collection("insurers").get();

    const insurers = [];

    data.docs.forEach((doc) => {
      insurers.push({ ...doc.data(), id: doc.id });
    });

    dispatch(getInsurers(insurers));
  };
};

const getInsurers = (insurers) => ({
  type: types.getInsurers,
  payload: {
    insurers,
  },
});
