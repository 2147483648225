import { auth, db, storageRef } from "../../firebase/firebase-config";
import { types } from "../types/types";

import { googleProvider } from "../../firebase/firebase-config";
export const SUPPORT_PHONE = "SUPPORT_PHONE";
export const refreshToken = async () => {
  const token = await auth.currentUser.getIdToken(true);

  return token;
};

export const login = (email, password, handleClose, setloading) => {
  return async (dispatch) => {
    try {
      // dispatch(setIsLoadingAuth(true));
      const response = await auth.signInWithEmailAndPassword(email, password);
      console.log("aca");
      console.log(response);
      const idToken = await response.user.getIdToken(true);
      dispatch(signInWithEmail(response.user.toJSON(), true, idToken));
      // dispatch(setIsLoadingAuth(false));
      dispatch(setAuthError(""));
      setloading(false);
      // Only close the modal when login is successful
      handleClose();
    } catch (error) {
      dispatch(setAuthError(error.code));
      setloading(false);
      // dispatch(setIsLoadingAuth(false));
      // You may want to handle the unsuccessful login here or show an error message to the user
      console.error("Login failed:", error.code);
    }
  };
};
export const loginWithGoogle = (handleAction) => {
  return async (dispatch) => {
    let skipFinally = false;

    try {
      const { user, additionalUserInfo } = await auth.signInWithPopup(
        googleProvider
      );
      const idToken = await user.getIdToken(true);

      dispatch(signInWithEmail(user.toJSON(), true, idToken));
    } catch (error) {
      console.error("Error during Google Sign-In:", error.message);

      skipFinally = true;

      // Optionally display an error message to the user
    } finally {
      if (handleAction && !skipFinally) {
        handleAction(); // Execute the post-action
      }
    }
  };
};

export const signUpWithEmailAndPassword = (email, password) => {
  return async (dispatch) => {
    try {
      //dispatch(setIsLoadingAuth(true));
      const { user, additionalUserInfo } =
        await auth.createUserWithEmailAndPassword(email, password);

      //const { publicData, privateData, walletData } = userData;

      // if (additionalUserInfo.isNewUser) {
      //   //await createUserProfileDocument(user);
      //   //await updateUserData(user.uid, publicData, privateData, "data");
      //   //await updateUserData(user.uid, undefined, walletData, "wallet");
      // }

      dispatch(signInWithEmail(user.toJSON()));
      //dispatch(setIsLoadingAuth(false));
      dispatch(setAuthError(""));
    } catch (error) {
      console.log(error);
      dispatch(setAuthError(error.code));
      //dispatch(setIsLoadingAuth(false));
    }
  };
};
export const uploadUserImageToAuth = async (imageFile) => {
  try {
    const user = auth.currentUser;

    if (user) {
      // Upload the image to the user's profile
      await user.updateProfile({
        photoURL: imageFile,
      });

      // The user's profile has been updated with the new image
      console.log("User image updated successfully.");
      return true;
    } else {
      console.error("User not authenticated.");
      return false;
    }
  } catch (error) {
    console.error("Error updating user image:", error);
    return false;
  }
};

export const uploadPhoto = async (uid, photoFromDevice) => {
  try {
    const ref = storageRef.ref(`/users/${uid}/profile`);
    const res = await ref.put(photoFromDevice);
    if (res.state === "success") {
      const imageURL = await ref.getDownloadURL();
      await updateAuthUserPhotoURL(imageURL);
      await db.doc(`users/${uid}`).set({ photoURL: imageURL }, { merge: true });
    }
  } catch (err) {
    console.log(err);
  }
};
export const updateAuthUserPhotoURL = (photoURL) => {
  const user = { photoURL };
  return auth.currentUser.updateProfile(user);
};
export const setAuthError = (errorCode) => {
  let errorMsg = "";

  switch (errorCode) {
    case "auth/user-not-found":
      errorMsg = "Usuario no encontrado. Verifique sus credenciales.";
      break;
    case "auth/wrong-password":
      errorMsg = "Contraseña incorrecta. Verifique sus credenciales.";
      break;
    // Add more error codes and corresponding messages as needed
    default:
      errorMsg = "Error en el inicio de sesión. Verifique sus credenciales.";
  }

  return {
    type: types.errorMsg,
    payload: {
      errorCode: errorCode,
      errorMsg: errorMsg,
    },
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    try {
      dispatch(logout());

      await auth.signOut();
    } catch (error) {
      console.error(error);
    }
  };
};
export const getSupportPhone = () => {
  return async (dispatch) => {
    try {
      const phone = await db.collection("utils").doc("contact").get();
      const supportPhone = phone.data().supportPhone;

      // Dispatch the action with the supportPhone value
      dispatch(setSupportPhone(supportPhone));
    } catch (error) {
      // Handle any potential errors here
      console.error("Error in getSupportPhone:", error);
      throw error; // You may choose to rethrow the error for handling in higher-level code
    }
  };
};

export const signInWithEmail = (user, loggedIn, idToken) => ({
  type: types.login,
  payload: {
    user,
    loggedIn: loggedIn,
    idToken: idToken,
  },
});
export const setIsLoadingAuth = (isLoading) => ({
  type: types.isLoadingAuth,
  payload: {
    isLoadingAuth: isLoading,
  },
});
export const setSupportPhone = (supportPhone) => ({
  type: SUPPORT_PHONE,
  payload: supportPhone,
});

export const logout = () => ({
  type: types.logout,
  payload: {},
});
