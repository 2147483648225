import React, { useCallback, useEffect, useRef, useState } from "react";
import { ProfileNavbar } from "../components/profile/ProfileNavbar";
import { Box, Tabs, Tab } from "@mui/material";

import "firebase/firestore";

import { EditProfileForm } from "../components/profile/EditProfileForm";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { getUserInfo } from "../store/actions/user";
import { useDispatch, useSelector } from "react-redux";

import Documentation from "../components/profile/Documentation";
import CardAdministration from "../components/profile/CardAministration";

const MySwal = withReactContent(Swal);

export const ProfilePage = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const { userInfo } = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState(0); // Initialize activeTab to 0
  const [selectedPlan, setSelectedPlan] = useState("");
  const isFirstUse = useRef(true);

  const getUserInfoFromFb = useCallback(async () => {
    console.log("getUserInfoFromFb");
    try {
      dispatch(getUserInfo(user.uid));
    } catch (error) {
      console.log(error);
    }
  }, [user, dispatch]);

  useEffect(() => {
    setLoading(true);
    if (userInfo) {
      setLoading(false);
    } else {
      getUserInfoFromFb();
      setLoading(false);
    }
    getUserInfoFromFb();
  }, [getUserInfoFromFb]);

  // useEffect(() => {
  //   if (isFirstUse.current) {
  //     setLoading(true);
  //     console.log("useEffect step 1");
  //     getUserInfoFromFb();
  //     isFirstUse.current = false;
  //   } else if (!userInfo) {
  //     setLoading(true);
  //     console.log("useEffect step 2");
  //     getUserInfoFromFb();
  //   } else if (userInfo) {
  //     setLoading(false);
  //   }
  // }, [getUserInfoFromFb, userInfo]);

  // useEffect(() => {
  //   console.log(userInfo);
  // }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const steps = [
    "Información Personal",
    "Documentación",
    // "Administrar mis tarjetas",
  ];

  const handleNext = (newData) => {
    // console.log("click");
  };

  const handleRefresh = () => {
    setLoading(true);

    // getAssociationFromFB();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleBack = (newData) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };
  return (
    <>
      {/* <ProfileNavbar /> */}

      <Tabs
        value={activeTab} // Use activeTab here
        onChange={handleTabChange} // Handle tab changes
        indicatorColor="primary"
        sx={{ marginTop: 1, marginLeft: 1 }}
      >
        {steps.map((label, index) => (
          <Tab key={index} label={label} />
        ))}
      </Tabs>

      <Box>
        {activeTab === 0 && (
          <EditProfileForm
            handleNext={handleNext}
            handleBack={handleBack}
            loading={loading}
            setLoading={setLoading}
            data={userInfo}
            activeStep={activeStep}
            handleRefresh={handleRefresh}
            userId={user.uid}
          />
        )}
        {activeTab === 1 && (
          <Documentation
            handleNext={handleNext}
            handleBack={handleBack}
            setSelectedPlan={setSelectedPlan}
            id={user.uid}
          />
        )}
        {activeTab === 2 && <CardAdministration />}
        {/* {activeTab == 1 && (
                <ActivePlans
                  handleNext={handleNext}
                  handleBack={handleBack}
                  activeStep={activeStep}
                  selectedPlan={selectedPlan}
                  setSelectedPlan={setSelectedPlan}
                />
              )} */}
        {/* {activeStep == 2 && (
            <B2bSummary
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              selectedPlan={selectedPlan}
              data={data}
            />
          )} */}
        {/* {activeStep == 3 && (
            <B2bSummary
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
            />
          )} */}
      </Box>
    </>
  );
};
