import { Modal } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch } from "react-redux";

import React, { useState } from "react";
import { Dialog, Box, Button, IconButton, useTheme } from "@mui/material";
import { MdClose } from "react-icons/md";

import SignUpForm from "../forms/SignUpForm";
import UpdateUserProfileForm from "../forms/UpdateUserProfleForm";

const RegisterModal = ({ open, setOpen, openLogIn }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const matches768 = useMediaQuery("(max-width:768px)");
  const dispatch = useDispatch();

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);
  const onClose = () => {
    setActiveStep(0);
    setOpen(false);
    openLogIn(false);
  };

  const steps = [
    {
      label: "Paso 1",
      content: (
        <SignUpForm
          handleNext={handleNext}
          handleClose={setOpen}
          handleOpenLogIn={openLogIn}
        />
      ),
    },
    {
      label: "Paso 2",
      content: (
        <UpdateUserProfileForm setOpen={setOpen} handleBack={handleBack} />
      ),
    },
  ];

  return (
    <Dialog
      fullWidth
      disableScrollLock
      maxWidth="lg"
      open={open}
      PaperProps={{
        sx: matches768
          ? {
              overflowY: "scroll",
              "&::-webkit-scrollbar": { width: 0 },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              bgcolor: "transparent",
              width: "100%",
              boxShadow: "none",
              borderRadius: "18px",
              overflow: "hidden",
            }
          : {
              overflowY: "scroll",
              "&::-webkit-scrollbar": { width: 0 },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              bgcolor: "transparent",
              boxShadow: "none",
              borderRadius: "18px",
              padding: "60px",
            },
      }}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 20,
          color: "white",
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <MdClose size={32} />
      </IconButton>
      <Box
        sx={{
          bgcolor: "#f5f6ff",
          padding: matches768 ? "2rem" : "2rem",
          borderRadius: "55px",
          width: "100%",
          overflow: "auto",
        }}
      >
        {steps[activeStep].content}
      </Box>
    </Dialog>
  );
};

export default RegisterModal;
