import {
  Box,
  Typography,
  Container,
  Grid,
  Avatar,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ProfileNavbar } from "./ProfileNavbar";
import circle from "../../assests/yellowCircle.png";
import { styles } from "../../styles/styles";
import { ASbutton } from "../buttons/ASbutton";
import { Sidebar } from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../store/actions/user";
import { getAllPlans } from "../../store/actions/plans";
import {
  IoBag,
  IoBagOutline,
  IoMail,
  IoMailOpenOutline,
  IoPersonRemoveOutline,
} from "react-icons/io5";

const ProfileLayout = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const { userInfo, isLoadingInfo } = useSelector((state) => state.user);

  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [userData, setUserData] = useState();

  const navigateTo = (link) => {
    navigate(link);
  };

  useEffect(() => {
    if (userInfo) {
      setUserData(userInfo);
    }
  }, [userInfo, isLoadingInfo]);

  return (
    <>
      <Container maxWidth={false} sx={styles.profileWrapper}>
        <Grid
          container
          justifyContent={{ xs: "center", md: "space-between" }}
          alignItems={"center"}
          sx={{ mt: 2, mb: 6 }}
        >
          <Grid item>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                [theme.breakpoints.down("sm")]: {
                  flexDirection: "column",
                  width: "100%",
                  margin: "0 auto",
                },
              }}
            >
              <Box
                component={"img"}
                alt="User Avatar"
                src={userInfo?.general?.photoURL}
                sx={{
                  mr: 2,
                  width: "90px",
                  height: "90px",
                  maxHeight: "90px",
                  borderRadius: "18px",
                  [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mr: 0,
                    mb: 2,
                  },
                }}
              />
              {isLoadingInfo || !userInfo ? (
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      [theme.breakpoints.down("sm")]: {
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        ml: 4,
                        [theme.breakpoints.down("sm")]: {
                          margin: "0 auto",
                        },
                      }}
                    >
                      <CircularProgress />
                    </Grid>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      [theme.breakpoints.down("sm")]: {
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    }}
                  >
                    <Typography>
                      {userData?.name} {userData?.lastName} {}
                    </Typography>
                    {userInfo.isActiveMember && (
                      <Typography
                        ml={1}
                        sx={{
                          fontSize: 11,
                          color: theme.palette.primary.main,
                          fontWeight: "bold",
                        }}
                      >
                        {"Activo"}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",

                      alignItems: "center",
                      [theme.breakpoints.down("sm")]: {
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    }}
                  >
                    <IoBagOutline size={18} className="inactive-icon" />
                    <Typography variant="body1" ml={1}>
                      {userData?.userPlan?.name ? userData.userPlan.name : ""}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",

                      alignItems: "center",
                      [theme.breakpoints.down("sm")]: {
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    }}
                  >
                    <IoMailOpenOutline size={18} className="inactive-icon" />
                    <Typography variant="body1" ml={1}>
                      {userData?.email}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          {!mobile && (
            <Grid item>
              <ASbutton
                buttonText={"Editar Perfil"}
                onClick={() => navigateTo("/profile/info")}
              />
            </Grid>
          )}
        </Grid>

        <Grid container justifyContent={"space-between"}>
          <Grid
            item
            md={3}
            lg={2.5}
            sm={12}
            xs={12}
            sx={{
              position: "relative",
              left: 0,
              top: 0,
              bottom: 0,
              width: "100%",
              //   backgroundColor: "#f0f0f0",
              zIndex: 1,
              paddingTop: 0,
              minHeight: "65vh",
              borderRadius: "18px",
              borderWidth: "2px !important",
              border: "solid",
              borderColor: "#ECECEE",
              maxHeight: "40vh",
              paddingRight: "0px",
              paddingLeft: "0px",
              [theme.breakpoints.down("md")]: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                maxHeight: "85px",
                minHeight: "85px",
                mb: 6,
              },
            }}
          >
            <Sidebar />
          </Grid>
          <Grid item md={8.5} lg={9} sm={12} xs={12} sx={styles.formCard}>
            <Outlet />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ProfileLayout;
