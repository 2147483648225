import * as yup from "yup";

const idTypesData = ["Nacional", "Dimex", "Nite"];

export const billingSchema = yup.object({
  name: yup.string().required("Campo Obligatorio"),
  lastName: yup.string().required("Campo Obligatorio"),
  secondLastName: yup.string().required("Campo Obligatorio"),
  idType: yup
    .string()
    .oneOf(idTypesData, "Debe seleccionar una de las opciones")
    .required("Campo Obligatorio"),
  personalId: yup.number().required("Campo Obligatorio"),
  phone: yup
    .number()
    .typeError("Formato no valido")
    .required("Campo Obligatorio"),
  email: yup
    .string()
    .email("Formato no valido")
    .typeError("Formato no valido")
    .required("Campo Obligatorio"),
});
