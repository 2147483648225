import jwtDecode from "jwt-decode";
import Form from "react-bootstrap/Form";
import { Stack, Button, Row, Col, Spinner } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Provinces as provinces } from "../../utils/provinces";
import { Cantons as cantons } from "../../utils/cantons";
import { Districts as districts } from "../../utils/districts";

import { useFormik } from "formik";

import { FiArrowRight } from "react-icons/fi";

import register from "../../assests/register.png";
import personalinfo from "../../assests/personalinfo.png";
import * as yup from "yup";
import { signUpFormFormSchema } from "../../schemas/signupSchema";
import { useDispatch, useSelector } from "react-redux";
import {
  refreshToken,
  signUpWithEmailAndPassword,
} from "../../store/actions/auth";

import "./formStyles.scss";
import { useState, useEffect } from "react";
import PurchaseRaceProgress from "../PurchaseRaceProgress/PurchaseRaceProgress";
import { fsTimestampCreador } from "../../firebase/firebase-config";
import {
  setSale,
  setUserToSigma,
  updateUserData,
} from "../../store/actions/user";
import Swal from "sweetalert2";

import withReactContent from "sweetalert2-react-content";
import { useTheme } from "@emotion/react";
import {
  checkAge,
  countries,
  fetchAndSaveUserData,
  getUserInfoAPI,
  idTypesData,
} from "../../utils/globals";

const MySwal = withReactContent(Swal);

const PersonalInformationForm = ({ handleNext, userData }) => {
  const theme = useTheme();
  const [filteredCantons, setFilteredCantons] = useState([]);
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const { idToken } = useSelector((state) => state.auth);
  const currentUser = useSelector((state) => state.auth.user);
  const [isNextValid, setIsNextValid] = useState(false);
  const [editableDate, setEditableDate] = useState(false); // Editable if birthday is not set
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const personalInformationFormSchema = yup.object({
    province: yup.string().required("Campo Obligatorio"),
    canton: yup.string().required("Campo Obligatorio"),
    district: yup.string().required("Campo Obligatorio"),
    idType: yup.string().required("Campo Obligatorio"),
    personalId: yup.number().required("Campo Obligatorio"),
    termsAndConditions: yup
      .boolean()
      .oneOf([true], "Debe aceptar antes de continuar"),
  });
  const {
    setFieldValue,
    handleChange,
    setValues,
    touched,
    errors,
    values,
    isValid,
    handleBlur,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: userData?.general?.name ?? "",
      lastName: userData?.general?.lastName ?? "",
      secondLastName: userData?.general?.secondLastName ?? "",
      idType: userData?.private?.idType ?? "",
      personalId: userData?.private?.personalId ?? "",
      dateOfBirth: userData?.general?.birthday?.toDate() ?? startDate,
      province: userData?.general?.province ?? "",
      canton: userData?.general?.canton ?? "",
      district: userData?.general?.district ?? "",
      termsAndConditions: false,
      address: userData?.general?.address ?? "",
      gender: userData?.general?.gender ?? "",
      notificationPermission:
        userData?.general?.notificationPermission ?? false,
      country_id: userData?.country_id ?? "", // New field for country
    },
    validationSchema: personalInformationFormSchema,
    onSubmit: async (values) => {
      const publicData = {
        name: values.name,
        lastName: values.lastName,
        secondLastName: values.secondLastName,
        province: values.province,
        canton: values.canton,
        district: values.district,
        address: values.address,
        notificationPermission: values.notificationPermission,
        birthday: fsTimestampCreador.fromDate(values.dateOfBirth),
        gender: values.gender,
        country_id: values.country_id, // This will be the selected country's id
      };

      const privateData = {
        personalId: values.personalId,
        idType: values.idType,
      };
      const data = {
        isWeb: true,
      };
      MySwal.fire({
        title: "Continuar? ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "No",
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const loadingSwal = MySwal.fire({
            title: "Guardando...",

            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              MySwal.showLoading();
            },
          });

          try {
            await updateUserData(
              currentUser.uid,
              publicData,
              privateData,
              "data"
            );
            if (isValidToken(idToken)) {
              const res = await setUserToSigma(data, idToken);

              if (res.ok || res.code === 11) {
                loadingSwal.close();
                handleNext();
              } else {
                MySwal.fire(
                  "Error",
                  "Hubo un error al enviar la solicitud",
                  "error"
                );
              }
            } else {
              const token = await refreshToken();
              const res = await setUserToSigma(data, token);
              console.log("res", res);

              if (res.ok || res.code === 11) {
                handleNext();
              } else {
                MySwal.fire(
                  "Error",
                  "Hubo un error al enviar la solicitud",
                  "error"
                );
              }
              loadingSwal.close();
            }
          } catch (error) {
            console.log(error);
            loadingSwal.close();
            MySwal.fire("Error", "", "error");
          }
        }
      });
    },
  });

  useEffect(() => {
    const selectedProvince = values.province;

    if (selectedProvince) {
      const filterItems = cantons.filter(
        (canton) => canton.provinceName === selectedProvince
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });
      setFilteredCantons(filterItems);
    }
  }, [values.province]);

  useEffect(() => {
    const selectedCanton = values.canton;

    if (selectedCanton) {
      const filterItems = districts.filter(
        (district) => district.cantonName === selectedCanton
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });
      setFilteredDistricts(filterItems);
    }
  }, [values.canton]);
  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };

  return (
    <Row>
      <Col xl="7">
        <PurchaseRaceProgress progressPorcentage={50} />
        <h4 className="fw-bold my-3">Información Personal</h4>

        <form>
          <Row>
            <Form.Group
              className="mb-3"
              controlId="groupIdType"
              as={Col}
              md="6"
            >
              <Form.Label>Tipo de documento</Form.Label>
              <Form.Select
                className="mb-3"
                aria-label="Tipo de documento"
                value={values.idType}
                onChange={handleChange}
                name="idType"
                onBlur={handleBlur}
                isInvalid={!!errors.idType && touched.idType}
              >
                <option value="">Seleccione una opcion</option>
                {idTypesData.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {errors.idType}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="groupId" as={Col} md="6">
              <Form.Label>Número de documento</Form.Label>
              <Form.Control
                type="number"
                className="mb-3"
                placeholder="Número de documento"
                onChange={handleChange}
                name="personalId"
                isInvalid={!!errors.personalId && touched.personalId}
                onBlur={handleBlur}
                value={values.personalId}
              />

              <Form.Control.Feedback type="invalid">
                {errors.personalId}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Stack direction="horizontal" className="mt-2 mb-4" gap={2}>
            <Button
              style={{
                padding: "10px 15px",
                borderRadius: "15px",
                minWidth: "150px",
              }}
              onClick={() =>
                fetchAndSaveUserData({
                  idType: values.idType,
                  personalId: values.personalId,
                  idToken,
                  setValues,
                  setEditableDate,
                  setIsNextValid,
                  setIsLoading,
                  setErrorMessage,
                })
              }
              variant="secondary"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Continuar"
              )}
            </Button>
          </Stack>

          {errorMessage && <p className="text-danger">{errorMessage}</p>}
          <Row>
            <Form.Group className="mb-3" controlId="groupName" as={Col} md="6">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese su nombre"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.name && touched.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="groupLastName"
              as={Col}
              md="6"
            >
              <Form.Label>Primer Apellido</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese su primer apellido"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.lastName && touched.lastName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="groupSecondLastName"
              as={Col}
              md="6"
            >
              <Form.Label>Segundo Apellido</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese su segundo apellido"
                name="secondLastName"
                value={values.secondLastName}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.secondLastName && touched.secondLastName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.secondLastName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="groupDate" as={Col} md="6">
              <Form.Label>Fecha de nacimiento</Form.Label>
              <DatePicker
                selected={
                  values.dateOfBirth ? new Date(values.dateOfBirth) : startDate
                }
                onChange={(val) => setFieldValue("dateOfBirth", val)}
                name="dateOfBirth"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className="form-control"
                disabled={!editableDate}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              className="mb-3"
              controlId="groupCountry"
              as={Col}
              md="6"
            >
              <Form.Label>País</Form.Label>
              <Form.Select
                aria-label="País"
                value={values.country_id}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.country_id && touched.country_id}
                name="country_id"
              >
                <option value="">Seleccione un país</option>
                {countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.country_id}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              className="mb-3"
              controlId="groupProvince"
              as={Col}
              md="4"
            >
              <Form.Label>Provincia</Form.Label>
              <Form.Select
                aria-label="Provincia"
                value={values.province}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.province && touched.province}
                name="province"
              >
                <option>Seleccione una opcion</option>
                {provinces.map((province) => (
                  <option>{province.label}</option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {errors.province}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="groupCanton"
              as={Col}
              md="4"
            >
              <Form.Label>Canton</Form.Label>
              <Form.Select
                aria-label="Canton"
                value={values.canton}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.canton && touched.canton}
                disabled={!values.province}
                name="canton"
              >
                <option>Seleccione una opcion</option>
                {filteredCantons.map((canton) => (
                  <option>{canton.label}</option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {errors.canton}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="groupCanton"
              as={Col}
              md="4"
            >
              <Form.Label>Distrito</Form.Label>
              <Form.Select
                aria-label="Distrito"
                value={values.district}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.district && touched.district}
                disabled={!values.canton}
                name="district"
              >
                <option>Seleccione una opcion</option>
                {filteredDistricts.map((district) => (
                  <option>{district.label}</option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {errors.district}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="groupId" as={Col} md="12">
              <Form.Label>Direccion</Form.Label>
              <Form.Control
                type="text"
                className="mb-3"
                placeholder="Direccion"
                onChange={handleChange}
                name="address"
                isInvalid={!!errors.address && touched.address}
                onBlur={handleBlur}
                value={values.address}
              />

              <Form.Control.Feedback type="invalid">
                {errors.address}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              name="termsAndConditions"
              onChange={handleChange}
              isInvalid={!!errors.termsAndConditions}
              label={
                <span>
                  He leído y acepto los{" "}
                  <a href="#" style={{ color: "#168FCA" }}>
                    Términos y condiciones
                  </a>
                </span>
              }
              feedback={errors.termsAndConditions}
              feedbackType="invalid"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              name="notificationPermission"
              onChange={handleChange}
              isInvalid={!!errors.notificationPermission}
              label={
                <span>
                  Deseo recibir novedades exclusivas a mi correo electrónico
                </span>
              }
              feedback={errors.notificationPermission}
              feedbackType="invalid"
            />
          </Form.Group>

          <Stack direction="horizontal" className="mt-4">
            <Button
              style={{
                padding: "10px 15px",
                borderRadius: "15px",
                minWidth: "150px",
              }}
              disabled={!isNextValid || !isValid || !values.termsAndConditions}
              onClick={handleSubmit}
              variant="secondary"
            >
              A la meta <FiArrowRight />
            </Button>
          </Stack>
        </form>
      </Col>
      <Col>
        <div
          className="position-absolute personal-info-form-exercise-girl d-none d-xl-block"
          style={{ width: "35%", top: "20px", right: "20px" }}
        >
          <img
            src={personalinfo}
            alt=""
            style={{ borderRadius: "55px", width: "100%" }}
          />
        </div>
      </Col>
    </Row>
  );
};
export default PersonalInformationForm;
