import { useState } from "react";
import ExchangesPromotions from "../ExchangesPromotions";
import MedicalAssistance from "../MedicalAssistance";
import MedicalInsurance from "../MedicalInsurance";

import "./responsiveAccordion.scss";
import SelectedPlan from "../../selectedPlan/SelectedPlan";
import { Container } from "react-bootstrap";
import { Box, useMediaQuery } from "@mui/material";

import { BsXLg } from "react-icons/bs";
import SelectedInsurer from "../../selectedInsurer/SelectedInsurer";

import ins from "../../../assests/ins.jpg";
import { useSelector } from "react-redux";

const ResponsiveAccordion = ({ handleClose, setOpenPayPlan, setPlan }) => {
  const [activeTab, setActiveTab] = useState(null);

  const handleTabClick = (index) => {
    setActiveTab((prevActiveTab) => (prevActiveTab === index ? null : index));
  };

  const applyToPlan = () => {
    handleClose(false);
    setOpenPayPlan(true);
    setPlan();
  };

  const { insurers } = useSelector((state) => state.insurer);

  let isFirstColor = true;

  const accordionData = insurers.map((currentInsurer) => {
    let nextColor = "";

    if (isFirstColor) {
      nextColor = "#3E3288";
      isFirstColor = !isFirstColor;
    } else {
      nextColor = "#625EA8";
      isFirstColor = !isFirstColor;
    }

    return {
      component: (
        <SelectedInsurer
          bgColor={nextColor}
          insurer={currentInsurer}
          applyToPlan={applyToPlan}
        />
      ),
      title: (
        <Box
          component="img"
          src={currentInsurer?.logo}
          sx={{
            zIndex: 400,
            transformOrigin: "center",
            width: "200px",
            //transform: "rotate(-90deg) translate(-75%, -75%)",
          }}
        />
      ),
    };
  });

  //This gives the name to the panels or tabs
  const getCurrentName = (index) => {
    const currentInsurer = insurers[index];

    return (
      <Box sx={{ width: "300px", height: "200px", marginBottom: 0 }}>
        <img
          src={currentInsurer?.logo}
          alt="Logo de aseguradora"
          style={{ objectFit: "contain", width: "100%", height: "100%" }}
        />
      </Box>
    );
  };

  // const accordionData = [
  //   {
  //     // component: <MedicalAssistance productData={plan} />,
  //     component: <SelectedInsurer bgColor="#3E3288" />,
  //     title: (
  //       <Box
  //         component="img"
  //         src={ins}
  //         sx={{
  //           zIndex: 400,
  //           transformOrigin: "center",
  //           width: "200px",
  //           //transform: "rotate(-90deg) translate(-75%, -75%)",
  //         }}
  //       />
  //     ),
  //     bg: "#3E3288",
  //   },
  //   {
  //     //component: <MedicalInsurance productData={plan} />,
  //     component: <SelectedInsurer bgColor="#625EA8" />,
  //     title: (
  //       <Box
  //         component="img"
  //         src={ins}
  //         sx={{
  //           zIndex: 400,
  //           transformOrigin: "center",
  //           width: "200px",
  //           //transform: "rotate(-90deg) translate(-75%, -75%)",
  //         }}
  //       />
  //     ),
  //     bg: "#625EA8",
  //   },
  //   // {
  //   //   component: <ExchangesPromotions productData={plan} />,
  //   //   title: "Canjes y Promociones",
  //   //   bg: "#ada4ff",
  //   // },
  // ];

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignItems: "start",
        position: "relative",
        width: "100%",
      }}
    >
      <ul className="content position-relative">
        {/* <SelectedPlan
          plan={plan}
          agedPlan={false}
          handleClose={handleClose}
          setOpenPayPlan={setOpenPayPlan}
        /> */}
        <span
          onClick={() => handleClose(false)}
          className="d-block d-xl-none"
          style={{
            position: "absolute",
            top: 20,
            left: "95%",
            color: "#fff",
            zIndex: 1500,
            cursor: "pointer",
          }}
        >
          <BsXLg />
        </span>
        {accordionData.map((item, index) => {
          return (
            <div
              className="inner-content"
              style={
                index === 0
                  ? {
                      backgroundColor: "#3E3288",
                      top: "-65px",
                      borderRadius: "0 55px 55px 55px",
                      padding: "15px",
                      position: "relative",
                      left: 0,
                      zIndex: 300,
                      boxShadow: "9px 4px 34px 0px rgba(0, 0, 0, 0.25)",
                    }
                  : index === 1
                  ? {
                      backgroundColor: "#625EA8",
                      top: "-145px",
                      borderRadius: "0 55px 55px 55px",
                      padding: "15px",
                      position: "relative",
                      left: 0,
                      zIndex: 200,
                      boxShadow: "8px 4px 27px 0px rgba(0, 0, 0, 0.25)",
                    }
                  : {
                      backgroundColor: "#ada4ff",
                      top: "-225px",
                      borderRadius: "0 55px 55px 55px",
                      padding: "15px",
                      position: "relative",
                      left: 0,
                      zIndex: 100,
                      boxShadow: "9px 4px 34px 0px rgba(0, 0, 0, 0.25)",
                    }
              }
            >
              <div style={{ height: "100px" }}></div>
              <li
                key={index}
                onClick={() => handleTabClick(index)}
                className="accordion__item"
                style={{ backgroundColor: item.bg, width: "100%" }}
              >
                <input
                  type="checkbox"
                  className="check"
                  checked={activeTab === index ? false : true}
                />
                {/* <i className="accordion__arrow"></i> */}
                <h2 className="accordion__title text-white">{item.title}</h2>
                <div className="accordion__content">{item.component}</div>
              </li>
            </div>
          );
        })}
      </ul>
      {/* </div> */}
    </Container>
  );
};

export default ResponsiveAccordion;
