import jwtDecode from "jwt-decode";
import { refreshToken } from "../store/actions/auth";

export const API_URL = `https://us-central1-allsports-69231.cloudfunctions.net`;

export const getUserInfoAPI = async (idType, idNumber, token) => {
  const url = `https://generals-tllml4bkja-uc.a.run.app/${idType}/${idNumber}`;

  // console.log(idType, idNumber, token)
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();

    // Log the response for debugging
    console.log(res);

    if (response.ok) {
      return res; // Return the user data
    } else {
      throw new Error(`Error fetching data: ${res.message || "Unknown error"}`);
    }
  } catch (error) {
    console.error("Error fetching user data", error);
    throw new Error(error);
  }
};

export const countries = [
  { id: 1, name: "Guatemala", flag: "🇬🇹" },
  { id: 2, name: "Costa Rica", flag: "🇨🇷" },
  { id: 3, name: "Honduras", flag: "🇭🇳" },
  { id: 4, name: "El Salvador", flag: "🇸🇻" },
  { id: 5, name: "Nicaragua", flag: "🇳🇮" },
  { id: 7, name: "Panamá", flag: "🇵🇦" },
  { id: 8, name: "México", flag: "🇲🇽" },
  { id: 9, name: "República Dominicana", flag: "🇩🇴" },
  { id: 10, name: "Trinidad y Tobago", flag: "🇹🇹" },
  { id: 11, name: "Jamaica", flag: "🇯🇲" },
  { id: 12, name: "Paraguay", flag: "🇵🇾" },
  { id: 13, name: "Aruba", flag: "🇦🇼" },
  { id: 14, name: "Belice", flag: "🇧🇿" },
  { id: 15, name: "Guyana", flag: "🇬🇾" },
  { id: 17, name: "Curaçao", flag: "🇨🇼" },
  { id: 18, name: "Isla de San Martín", flag: "🇸🇽" },
  { id: 19, name: "Barbados", flag: "🇧🇧" },
  { id: 20, name: "Bonaire, San Eustaquio y Saba", flag: "🇧🇶" },
  { id: 21, name: "Antigua y Barbuda", flag: "🇦🇬" },
  { id: 22, name: "Dominica", flag: "🇩🇲" },
  { id: 23, name: "San Vicente y las Granadinas", flag: "🇻🇨" },
  { id: 24, name: "Santa Lucía", flag: "🇱🇨" },
  { id: 25, name: "Granada", flag: "🇬🇩" },
  { id: 26, name: "San Cristóbal y Nieves", flag: "🇰🇳" },
  { id: 27, name: "Ecuador", flag: "🇪🇨" },
  { id: 28, name: "Colombia", flag: "🇨🇴" },
  { id: 29, name: "Brasil", flag: "🇧🇷" },
  { id: 30, name: "Chile", flag: "🇨🇱" },
  { id: 31, name: "Bahrein", flag: "🇧🇭" },
  { id: 32, name: "Bailía de Guernsey", flag: "🇬🇬" },
  { id: 33, name: "Bangladés", flag: "🇧🇩" },
  { id: 35, name: "Belarús", flag: "🇧🇾" },
  { id: 36, name: "Bélgica", flag: "🇧🇪" },
  { id: 38, name: "Benín", flag: "🇧🇯" },
  { id: 39, name: "Bermudas", flag: "🇧🇲" },
  { id: 40, name: "Bolivia", flag: "🇧🇴" },
  { id: 42, name: "Bosnia-Herzegovina", flag: "🇧🇦" },
  { id: 43, name: "Botsuana", flag: "🇧🇼" },
  { id: 45, name: "Brunéi", flag: "🇧🇳" },
  { id: 46, name: "Bulgaria", flag: "🇧🇬" },
  { id: 47, name: "Burkina Faso", flag: "🇧🇫" },
  { id: 48, name: "Burundi", flag: "🇧🇮" },
  { id: 49, name: "Bután", flag: "🇧🇹" },
  { id: 50, name: "Cabo Verde", flag: "🇨🇻" },
  { id: 51, name: "Camboya", flag: "🇰🇭" },
  { id: 52, name: "Camerún", flag: "🇨🇲" },
  { id: 53, name: "Canadá", flag: "🇨🇦" },
  { id: 54, name: "Catar", flag: "🇶🇦" },
  { id: 55, name: "Chad", flag: "🇹🇩" },
  { id: 56, name: "Chequia", flag: "🇨🇿" },
  { id: 58, name: "China", flag: "🇨🇳" },
  { id: 59, name: "Chipre", flag: "🇨🇾" },
  { id: 60, name: "Ciudad del Vaticano", flag: "🇻🇦" },
  { id: 62, name: "Comoras", flag: "🇰🇲" },
  { id: 63, name: "Congo", flag: "🇨🇬" },
  { id: 64, name: "Corea del Norte", flag: "🇰🇵" },
  { id: 65, name: "Corea del Sur", flag: "🇰🇷" },
  { id: 66, name: "Costa de Marfil", flag: "🇨🇮" },
  { id: 68, name: "Croacia", flag: "🇭🇷" },
  { id: 69, name: "Cuba", flag: "🇨🇺" },
  { id: 71, name: "Dinamarca", flag: "🇩🇰" },
  { id: 74, name: "Egipto", flag: "🇪🇬" },
  { id: 76, name: "Emiratos Árabes Unidos", flag: "🇦🇪" },
  { id: 77, name: "Eritrea", flag: "🇪🇷" },
  { id: 78, name: "Eslovaquia", flag: "🇸🇰" },
  { id: 79, name: "Eslovenia", flag: "🇸🇮" },
  { id: 80, name: "España", flag: "🇪🇸" },
  { id: 81, name: "Estados Federados de Micronesia", flag: "🇫🇲" },
  { id: 82, name: "Estados Unidos", flag: "🇺🇸" },
  { id: 83, name: "Estonia", flag: "🇪🇪" },
  { id: 84, name: "Esuatini", flag: "🇸🇿" },
  { id: 85, name: "Etiopía", flag: "🇪🇹" },
];

export const idTypesData = [
  {
    value: "0",
    label: "Nacional",
    id: 0,
  },
  {
    value: "1",
    label: "Dimex",
    id: 1,
  },
  // {
  //   value: "2",
  //   label: "Juridica",
  //   id: 2,
  // },
  {
    value: "3",
    label: "Nite",
    id: 2,
  },
];

export const checkAge = (dateOfBirth) => {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age >= 18;
};

export const isValidToken = (token) => {
  if (!token) {
    return false; // If token is missing or undefined, it is not valid
  }

  const decodedToken = jwtDecode(token);
  const currentTime = Math.floor(Date.now() / 1000);

  return currentTime < decodedToken.exp;
};

export const getValidToken = async (idToken) => {
  if (isValidToken(idToken)) {
    return idToken;
  }
  return await refreshToken();
};

export const toSentenceCase = (str) => {
  if (!str) {
    return "";
  }
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const fetchAndSaveUserData = async ({
  idType,
  personalId,
  idToken,
  setValues,
  setEditableDate,
  setIsNextValid,
  setIsLoading,
  setErrorMessage,
}) => {
  setIsLoading(true);
  setErrorMessage("");

  try {
    const token = await getValidToken(idToken);

    const response = await getUserInfoAPI(idType, personalId, token);

    if (response && response.client) {
      const client = response.client;

      if (!checkAge(client.dateOfBirth)) {
        setErrorMessage(
          "La fecha de nacimiento indica que el usuario es menor de 18 años."
        );
        setEditableDate(true);
        setIsNextValid(false);
        return;
      }

      setValues((prevValues) => ({
        ...prevValues,
        name: toSentenceCase(client.name) ?? "",
        lastName: toSentenceCase(client.lastName) ?? "",
        secondLastName: toSentenceCase(client.secondLastName) ?? "",
        phone: client.phoneNumbers.length ? client.phoneNumbers[0] : "",
        personalId: client.personalId ?? prevValues.personalId,
        province: prevValues.province,
        canton: prevValues.canton,
        district: prevValues.district,
        dateOfBirth: client.dateOfBirth ? new Date(client.dateOfBirth) : null,
        gender: client.sexCode ?? prevValues.gender,
      }));

      setEditableDate(!client.dateOfBirth);
      setIsNextValid(true);
    } else {
      setErrorMessage(
        "No se encontró información para el ID proporcionado. Por favor, inténtelo de nuevo."
      );
      setIsNextValid(false);
    }
  } catch (error) {
    console.error("Error fetching user data", error);
    setErrorMessage(
      "Ocurrió un error. Por favor ingrese la fecha de nacimiento manualmente."
    );
    setEditableDate(true);
    setIsNextValid(false);
  } finally {
    setIsLoading(false);
  }
};
