import { useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

const InsuranceSummary = ({
  plan,
  priceToCharge,
  apportionmentPrice,
  basePrice,
}) => {
  const ivaPrice = basePrice * 0.13;
  const matches720 = useMediaQuery("(max-width:720px)");
  const matches768 = useMediaQuery("(max-width:768px)");
  const matches1200 = useMediaQuery("(max-width:1199px)");

  return (
    <>
      <h4 className="mt-2 fw-bold">Resumen de compra</h4>
      <div
        className={matches720 ? "" : "d-flex"}
        style={{
          height: matches720 ? "auto" : "140px",
          position: "relative",
          //marginBottom: matches720 && "160px",
        }}
      >
        <Card
          className="border-0"
          style={{
            backgroundColor: "#8e95ff",
            borderRadius: "18px",
            width: matches720 ? "100%" : "50%",
            marginBottom: matches720 && "10px",
            position: "relative",
            zIndex: 100,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Card.Body className="text-white">
            <p className="mb-0">
              Plan:{" "}
              <span className="fw-bold">{plan.name ? plan.name : "N/A"}</span>
            </p>
            <p className="mb-0">
              Precio: <span className="fw-bold">{basePrice.toFixed(2)}</span>
            </p>
            <p className="mb-0">
              I.V.A: <span className="fw-bold">{ivaPrice.toFixed(2)}</span>
            </p>
            <p className="mb-0">
              Total: <span className="fw-bold">{priceToCharge}</span>
            </p>
          </Card.Body>
        </Card>
        <Card
          className="border-0"
          style={{
            backgroundColor: "#b5b9ff",
            borderRadius: "18px",
            position: matches720 ? "initial" : "absolute",
            left: matches1200 ? "260px" : "170px",
            height: "140px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Card.Body
            className="text-white"
            style={{
              fontSize: "14px",
              padding: "10px",
              paddingLeft: matches768 ? (matches720 ? "10px" : "100px") : "",
            }}
          >
            <Row>
              <Col md="4"></Col>
              <Col md="8">
                <p>
                  El monto de tu plan {plan.subTier} fue ajustando al{" "}
                  {new Date().toLocaleDateString("CR")}. Al activar este plan
                  autorizas su recurrencia mensual el primer día del mes en
                  curso por un total de {priceToCharge} I.V.A.I. mensuales.
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default InsuranceSummary;
