import { db } from "../../firebase/firebase-config";
import { types } from "../types/types";

export const getAllPlans = () => {
  return async (dispatch) => {
    const data = await db
      .collection("plans")
      .where("isActive", "==", true)
      .get();

    const items = [];

    data.docs.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });

    const plans = items.filter((item) => {
      if (item.name !== "Training" && item.name !== "Testing") {
        return true;
      } else {
        return false;
      }
    });

    plans.sort((firstValue, secondValue) =>
      firstValue.code.localeCompare(secondValue.code)
    );

    dispatch(getPlans(plans));
  };
};
export const getProducts = async () => {
  const productsFromDb = [];
  try {
    const response = await db.collection("plans").orderBy("cdate", "asc").get();

    // Fetch each product's insurer details and add insurerName
    for (const product of response.docs) {
      const productData = product.data();

      // Fetch the insurer details based on insurerId in the product
      const insurerResponse = await db
        .collection("insurers")
        .doc(productData.insurerId)
        .get();

      // Get the insurer name from the fetched document
      const insurerName = insurerResponse.exists
        ? insurerResponse.data().name
        : "Unknown Insurer";

      // Add insurerName to the product data
      productsFromDb.push({ id: product.id, insurerName, ...productData });
    }

    return productsFromDb;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getInsuranceCompanies = async () => {
  const insurersFromDb = [];
  try {
    const response = await db
      .collection("insurers")
      .orderBy("cdate", "desc")
      .get();

    response.docs.forEach((insurer) => {
      insurersFromDb.push({ id: insurer.id, ...insurer.data() });
    });

    return insurersFromDb;
  } catch (error) {
    console.error("Error fetching insurers:", error);
    return [];
  }
};

export const getProductsByInsurer = async (id) => {
  const productsFromDb = [];

  try {
    // Query the products collection for documents where the insurer field matches the provided insurer
    const response = await db
      .collection("plans")
      .where("insurerId", "==", id)
      .orderBy("code", "asc")
      .get();

    response.docs.forEach((product) => {
      productsFromDb.push({ id: product.id, ...product.data() });
    });

    return productsFromDb;
  } catch (error) {
    console.error("Error fetching products by insurer:", error);
    return []; // Return an empty array in case of error
  }
};
export const getProduct = async (id) => {
  try {
    const product = await db.collection("plans").doc(id).get();

    return product.data();
  } catch (error) {
    console.log(error);
  }
};

const getPlans = (plans) => ({
  type: types.getPlans,
  payload: {
    plans,
  },
});
